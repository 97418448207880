.container-fluid {
  padding-right: 50px;
  padding-left: 50px;
}
html,
body {
  width: 100%;
  overflow-x: hidden;
}
body {
  @include typeDefault;
}
a {
  @include typeLink;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  @include typeHeader;
}
p {
  margin:0 0 2em;
}

#site-footer ~ #site-main {
  display: none;
}

.error404 {
  .post__content__wrapper {
    padding-top:em(120);
    padding-bottom:em(120);
  }
}
