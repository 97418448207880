.page-education-advocacy {
  .post__section-related {
    background:$blueGrayLight;
    .post__related__additional {
      background:$purple;
    }
    + .post__section-related {
      background:transparent;
    }
  }
}