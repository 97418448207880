.post__content,.block-editor-writing-flow {
  [class*="wp-block"] {
    &.wp-block-group {
      padding-top: 2 * $grid-gutter-width;
      padding-bottom: 2 * $grid-gutter-width;
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }
    &:not(.has-background) {
      + .wp-block-separator,+ hr,+ h2 {
        margin-top:0;  
      }
    }
  }
  .wp-block-separator,h2,hr {
    + [class*="wp-block"]:not(.has-background) {
      padding-top:0;
    }
  }
  > .component {
    @extend .container-breakout;
  }
  > [class*="wp-block"]:not(ul),hr {
    padding-top: 2 * $grid-gutter-width;
    padding-bottom: 2 * $grid-gutter-width;
    &:last-child {
      margin-bottom:-2 * $grid-gutter-width;
    }
    &.wp-block-group,&.wp-block-coblocks-shape-divider {
      padding-left: 0;
      padding-right: 0;
      @extend .container-breakout;
      // added this descendent specificity because the container styles were being applied to nested blocks which added too much padding and whatnot
      // e.g. See orange cards on: https://nirhealth.org/the-learning-and-accountability-project-lap/
      > .wp-block-group__inner-container {
        @extend .container;
        > :first-child {
          margin-top:0;
        }
      }
      &.has-white-color.has-text-color {
        @extend .component, .component-contrast;
      }
    }
  }
  .wp-block-coblocks-shape-divider {
    padding:0;
  }
  .wp-block-separator,hr {
    padding:0;
    height:0;
    border-top:1px solid $blueMedium;
    border-bottom:none;
    margin:2 * $grid-gutter-width 0;
    opacity:1;
  }
  .wp-block-columns {
    margin-bottom:0;
    .wp-block-group {
      padding:0;
      margin:0;
      &.has-background {
        margin-bottom: $grid-gutter-width;
      }
      .wp-block-group__inner-container {
        padding:$grid-gutter-width 24px;
        width:auto;
        max-width:none;
        margin:0;
      }
    }
  }
  .wp-block-buttons {
    margin-top: 2 * $grid-gutter-width;
    margin-bottom: 2 * $grid-gutter-width;
    border-top:1px solid $blueMedium;
    border-bottom:1px solid $blueMedium;
    &.nav-subpage {
      padding:0;
      border:0;
      margin-top:0;
      .wp-block-button__link {
        @extend .btn-sharp;
        &:hover {
          filter:brightness(.9);
        }
      }
    }
  }
  .wp-block-button__link,
  .wp-block-file__button {
    @extend .btn;
    @include typeButtonEm;
    @include typeContrast;
  }
  .wp-block-file__embed {
    @include materialShadow;
  }
  .wp-block-coblocks-highlight {
    background:none;
    @include typeDefaultXL;
    border-left:em(25,30) solid $blueLight;
    padding:0 0 0 em(25,30);
    margin:2 * $grid-gutter-width 0;
    .wp-block-coblocks-highlight__content {
      background:none;
      padding:0;
    }
  }
  .wp-block-gallery {
    &.wp-block-gallery-full {
      @extend .container-breakout;
      padding-bottom:0;
      padding-top:0;
      &.has-nested-images {
        figure.wp-block-image:not(#individual-image) {
          margin:0;
        }
      }
    }
  }
  .wp-block-gallery-3,.wp-container-4 {
    gap:0;
  }
}