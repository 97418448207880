.post__preview-staff {
  &,.page .post__content & {
    .card__link {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      z-index:2;
    }
    .post__contact {
      a {
        text-decoration:underline;
        position:relative;
        z-index:3;
      }
    }
    &.card-individual,&.card-block {
      .post__contact {
        a {
          @include typeLinkContrast;
        }
      }
    }
    &.card-default {
      padding-top:160%;
      .card__image {
        flex:none;
        height:60%;
      }
      .card__content__wrapper {
        height:40%;
      }
      .card__content {
        height:100%;
        .card__section__inner {
          height:100%;
          display:flex;
          flex-direction:column;
          justify-content:space-between;
        }
      }
      .post__title {
        @include typeDefaultXL;
      }
    }
    &.card-individual {
      box-shadow:none;
      background:none;
      .card__image {
        height:em(208);
        width:em(208);
        border-radius:100%;
        @include materialShadow;
        margin-bottom:em(40);
        overflow:hidden;
        .card__section__inner,a,.post__image {
          height:100%;
          width:100%;
          display:block;
          padding:0;
        }
      }
      .post__title {
        @include typeDefaultLarge;
        @include typeContrast;
      }
      .card__content__wrapper {
        border-left:em(25) solid $light;
        padding-left:em(18);
      }
    }
    &.card-block {
      &:hover {
        .card__content__wrapper {
          opacity:1;
        }
      }
      position:relative;
      background:$blueMedium;
      .card__content__wrapper {
        @extend .component, .component-contrast;
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        opacity:0;
        transition:opacity .5s ease-in;
        background:rgba($dark,.4);
        padding:em(40) em(50);
      }
      .card__image {
        .post__image {
          width:100%;
          padding-top:87%;
          height:auto;
        }
      }
      .post__title {
        @include typeHeaderXS;
        @include typeContrast;
        margin-bottom:1em;
      }
      .post__position {
        line-height:1.2em;
      }
    }
  }
}