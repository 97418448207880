button,
.button,
.btn {
  display: inline-block;
  background: $blueMedium;
  border-radius: 2px;
  padding: 0.66em 1.33em;
  transition: all 0.3s ease-out;
  @include typeButton;
  color: $light;
  @extend %link-solid;
  @extend %link-blue;
  &:hover {
    color: $light;
  }
  &.btn-sharp {
    border-radius: 0;
  }
  &.btn-large {
    @include typeButtonLarge;
  }
  &.btn-alt {
    @include typeAlt;
    border-color: $blueDark;
    &:hover {
      color: $light;
      border-color: $blueMedium;
    }
  }
  &.btn-trans {
    background: none;
    border:1px solid $light;
    color:$light;
    &:hover {
      background:$light;
      border-color:$light;
      color:$blueDark;
    }
  }
  &.btn-trans-alt {
    border-color:$blueDarkest;
    color:$blueDarkest;
    &:hover {
      background-color:$blueMedium;
      border-color:$blueMedium;
      color:$light;
    }
  }
  &.btn-contrast {
    color: $light;
    border-color: $light;
    @extend .btn-hover-blue;
  }
  &.btn-bright,&.btn-yellow {
    @extend %link-solid;
    @extend %link-yellow;
  }
  &.btn-dark {
    background:$blueDarkest;
    color:$light;
  }
  &.btn-hover-blue {
    &:hover {
      color: $light;
      background: $blueMedium;
      border-color: $blueMedium;
    }
  }
  &.btn-hover-yellow {
    &:hover {
      color: $blueDark;
      background: $yellow;
      border-color: $yellow;
    }
  }
  &.btn-purple {
    background:$purple;
    color:$light;
    border:2px solid $light;
    &:hover {
      background:darken($purple,10%);
      color:$light;
      border:2px solid $light;
    }
  }
}
