.page-jobs {
  .post__content {
    padding-top:0;
    padding-bottom:0;
    counter-reset: section;
    h2 {
      margin-top:0;
    }
    .component {
      .component__title,.subsection__title {
        @include typeHeaderLarge;
        margin-bottom:1.5em;
      }
    }
    .component-jobs-benefits,.component-jobs-environment,.component-jobs-values {
      counter-increment: section;
      .component__title {
        position:relative;
        &:before {
          content: counters(section,".",decimal-leading-zero);
          color:$blueLight;
          font-weight:900;
          font-size:1.33em;
          position:absolute;
          right:calc(100% + #{em(20,70)});
          bottom:.5em;
        }
        @include media-breakpoint-down(lg) {
          &:before {
            right:auto;
            left:0;
            bottom:100%;
            margin-bottom:.25em;
          }
        }
      }
    }
    .component-jobs-eeo,.component-jobs-environment.component-contrast,.component-jobs-values {
      .component__title {
        @include typeContrast;
      }
    }
  }
}

.component-jobs-eeo {
  padding-top:em(111);
  padding-bottom:em(177);
  .component__title {
    @include typeContrast;
  }
  .component__description {
    @include typeDefaultLarge;
    @include typeContrast;
  }
  .nav-jump {
    @include typeDefaultMid;
    @include typeContrast;
    counter-reset: link-list;
    span {
      display:inline-block;
      margin-right:1em;
    }
    ul {
      display:inline-flex;
    }
    li {
      counter-increment: link-list;
      &:last-child:after {
        content:none;
      }
      &:before {
        content: counters(link-list, ".", decimal-leading-zero);
        display:inline-block;
        margin-right:.25em;
      }
      &:after {
        content: " / ";
        display:inline-block;
        margin-left:1em;
        margin-right:1em;
      }
    }
    a {
      @include typeLinkContrast;
    }
    @include media-breakpoint-down(sm) {
      ul,li {
        display:block;
      }
      li {
        &:after {
          content:none;
        }
      }
    }
  }

  .scroll-prompt {
    @include typeHeaderXS;
    height:0;
    overflow:visible;
    position:relative;
    top:em(100);
    font-weight:bold;
    display:inline-block;
    a {
      text-decoration:none;
      position:relative;
      display:inline-block;
      padding-right:em(30);
      &:after {
        content:"";
        background:transparent url("images/icon-chevron-down.svg") center center no-repeat;
        background-size:contain;
        width:1em;
        height:.8em;
        position:absolute;
        right:0;
        top:.2em;
        filter:invert(1);
      }
    }
  }
}

.component-jobs-jobs {
  padding-top:em(134);
  padding-bottom:em(141);
  hr {
    border-color:$blueDark;
    margin-bottom:2em;
  }
  .post__preview-job {
    height:100%;
    margin-bottom:0;
  }
  .list-careers {
    li {
      margin-bottom:$grid-gutter-width;
    }
  }
}

.component-jobs-benefits {
  padding-top:em(121);
  padding-bottom:em(132);
  .component__description {
    @include typeDefaultLarge;
  }
}

.component-jobs-environment {
  padding-top:em(121);
  padding-bottom:em(195);
  background:transparent center center no-repeat;
  &.component-contrast {
    .component__description {
      @include typeContrast;
    }
  }
  &:before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:$dark;
    opacity:.75;
    mix-blend-mode:multiply;
  }
  .component__description {
    @include typeDefaultLarge;
    max-width:em(800,21);
  }
}

.component-jobs-values {
  padding-top:em(121);
  padding-bottom:em(185);
  .component__description {
    @include typeDefaultLarge;
    @include typeContrast;
  }
}