// ==== STYLES ==== //
/*!
 Theme Name:   NIRH 2022
 Theme URI:    https://weareoutright.com
 Description:  NIRH 2022 Theme
 Author:       Outright
 Author URI:   https://weareoutright.com
 Version:      1.3.6
 License:      GNU General Public License v2 or later
 License URI:  http://www.gnu.org/licenses/gpl-2.0.html
 Text Domain:  nirh2022
*/

@use "sass:math";

$bootstrap-icons-font-src: url("../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff2")
    format("woff2"),
  url("../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff")
    format("woff");
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons";

@import "variables";
@import "typography";
@import "grid";
@import "base";

// Components: Modular elements styles
@import "components/component";
@import "components/icons";
@import "components/links";
@import "components/lists";
@import "components/buttons";
@import "components/highlight";
@import "components/pills";
@import "components/cards";
@import "components/forms";
@import "components/searchform";
@import "components/archive-filters";
@import "components/pulse";
@import "components/post-preview";
@import "components/post-preview_impact";
@import "components/post-preview_news";
@import "components/post-preview_resource";
@import "components/post-preview_staff";
@import "components/post-preview_job";
@import "components/site-header";
@import "components/site-footer";
@import "components/pagination";
@import "components/national-map";
@import "components/filter-summary";
@import "components/popup";
@import "components/lap-resources";

// Nav: navigation elements
@import "nav/nav";
@import "nav/nav-site";
@import "nav/nav-utility";
@import "nav/nav-footer";
@import "nav/nav-social";
@import "nav/nav-our_impacts";
@import "nav/nav-breadcrumbs";

// Blocks: Reusable blocks
@import "blocks/wp-blocks";
@import "blocks/hero-with-cta";
@import "blocks/motto";
@import "blocks/strategy";
@import "blocks/donate-cta";
@import "blocks/email-cta";
@import "blocks/hp-impacts";
@import "blocks/photo-quote";
@import "blocks/related-content";
@import "blocks/headshot-quote";
@import "blocks/testimonial";
@import "blocks/testimonial-carousel";
@import "blocks/twitter-feed";
@import "blocks/timeline";
@import "blocks/timeline-slider";
@import "blocks/join-nirh-cta";
@import "blocks/meet-staff-cta";

// Posts: Post types
@import "posts/single";
@import "posts/impact";
@import "posts/resource";
@import "posts/event";
@import "posts/staff";

// Pages: Import every page that has its own specific styles
@import "pages/archive";
@import "pages/home";
@import "pages/our-impact";
@import "pages/who-we-are";
@import "pages/proactive-policy";
@import "pages/education-advocacy";
@import "pages/donate";
@import "pages/where-we-work";
@import "pages/search";
@import "pages/staff";
@import "pages/events";
@import "pages/jobs";
@import "pages/news";
