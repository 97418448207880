.component-lap-resources {
    .col-12 {
        .post_related {
            .row {
                .card {
                    .card__section.card__image {
                        .card__section__inner {
                            .post__image {
                                height: 100% !important;
                                min-height: 100px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: 991px) and (max-width: 1250px ) {
        .col-12 {
            .post_related {
                .row {
                    .card {
                        .card__section.card__image {
                            .card__section__inner {
                                .post__image {

                                }
                            }
                        }
                        .card__content__wrapper {
                            .card__section.card__content {
                                .card__section__inner {
                                    h3 {
                                        font-size: em(18) !important;
                                        padding-top: em(45) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}