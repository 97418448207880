.component-photo-quote {
  padding:em(112) 0 em(130);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  &:before {
    content:"";
    position:absolute;
    z-index:1;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background:rgba(#000,.45);
  }
  .quote__quote {
    position:relative;
    z-index:2;
    blockquote {
      margin:0;
      padding:0;
      text-transform:uppercase;
      span {
        @include typeHeaderSuperLarge;
        line-height:1.25em;
        position:relative;
        &:before,&:after {
          position:absolute;
        }
        &:before {
          content:url("images/component-quote-open.png");
          right:100%;
          top:0;
          transform: translate(-40%,-40%);
        }
        &:after {
          content:url("images/component-quote-close.png");
          left:100%;
          bottom:0;
          transform: translate(40%,-40%);
        }
      }
      figcaption {
        @include typeDefaultMid;
        font-weight:800;
        color:$yellow;
        margin-top:2em;
      }
    }
  }
}

.post__content {
  .component-photo-quote {
    .quote__quote {
      blockquote {
        span {
          @include typeHeaderLarge;
        }
      }
    } 
  }
}