.component-timeline-slider {
    padding: 2em 0 0 0;
    background-color: $blueDarkest;

    .container {

        .top {
            width: 100%;

            .section-title {
                font-size: 4.375em;
                color: #FFF;
            }

            #range-container {
                /*  This was for a jQuery driven slider 
                #slider .ui-slider-handle {
                        outline: 0;
                        width: 10px;
                    height: 50px;
                    background: #FFF;
                    border-radius: 5px;
                    top: -25px;
                }

                #slider.ui-slider-horizontal {
                    top: 0;
                    left: 0;
                    height: 1px;
                    width: 100%;
                    border: none;
                    background: #FFF;
                }
                */
                #range-slider {
                    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
                    width: 100%; /* Specific width is required for Firefox. */
                    background: #FFF; /* Otherwise white in Chrome */
                    height: 1px;
                }

                #range-slider:focus {
                    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
                }

                #range-slider::-ms-track {
                    width: 100%;
                    cursor: pointer;
                    height: 1px;
                    /* Hides the slider so custom styles can be added */
                    background: #FFF;
                    border-color: transparent;
                    color: transparent;
                }

                #range-slider::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    border: none;
                    height: 47px;
                    width: 10px;
                    border-radius: 5px;
                    background: #ffffff;
                    cursor: pointer;
                    margin-top: -5px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
                    border-radius: 5px;
                }
                /* All the same stuff for Firefox */
                #range-slider::-moz-range-thumb {
                    border: none;
                    height: 47px;
                    width: 10px;
                    border-radius: 5px;
                    background: #ffffff;
                    cursor: pointer;
                }
                /* All the same stuff for IE */
                #range-slider::-ms-thumb {
                    border: none;
                    height: 47px;
                    width: 10px;
                    border-radius: 5px;
                    background: #ffffff;
                    cursor: pointer;
                }

                padding-bottom: 60px;
            }
            /* custom slider */
            #range-container {

                #guide {
                    border-bottom: 1px solid #FFF;
                    width: 100%;
                    height: 1px;
                    position: relative;
                }

                #handle {
                    position: absolute;
                    border: none;
                    height: 47px;
                    width: 10px;
                    border-radius: 5px;
                    background: #ffffff;
                    cursor: pointer;
                    top: -23.5px;
                    transform-origin: center center;
                }
            }

            #years-block-container {
                display: flex;
                align-items: center;
                width: 100%;
                margin-bottom: 8px;
                position: relative;

                .event-representation {
                    height: 33px;
                    margin-right: 4px;
                    cursor: pointer;
                    /* default bg color */
                    background-color: #ffc60b;
                    position: relative;
                }

                .event-representation:last-child {
                    margin-right: 0;
                }
            }

            #years-text-container {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 100%;
                margin-bottom: 20px;

                p {
                    font-size: 1.5625em;
                    color: #FFF;
                }

                p.bold {
                    font-weight: 700
                }
            }
        }

        .timeline__content {
            padding: 0;
            margin: 0;
        }
    }

    #timeline-slider-container {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0;
        margin: 0;

        .slick-prev, .slick-next {
            position: relative;
            z-index: 10;
        }

        .slick-next {
            right: 30px;
        }

        .slick-prev {
            transform: rotate(180deg);
            left: 30px;
        }

        .slick-list, .slick-track {
        }

        .timeline-item {
            height: 658px;
            position: relative;
            padding: 0;
            margin: 0;
            &,&:before {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;                
            }
            &:before {
                content:"";
                display:none;
                background-image: inherit;
                order:2;
            }
            .timeline-item-content-container {
                width: 100%;
                max-width: 1100px;
                margin: 0px auto;
                order:1;

                .timeline-item-content {
                    position: relative;
                    width: 500px;
                    height: auto;
                    top: 0;
                    left: 0;
                    background-color: $yellow;
                    padding: 30px;
                    //background-image: url(images/timeline_bg.png);
                    background-repeat: no-repeat;
                    background-position: bottom right;
                    background-size: 264px 143px;

                    @media only screen and (max-width: 600px) {
                        width: 100%;
                        flex:none;
                    }

                    .title {
                        font-size: 10.375em;
                        line-height: 1em;
                        margin: 0;
                        padding: 0 0 20px 0;
                        color: $blueDarkest;
                        font-weight: 700;

                        @media only screen and (max-width: 600px) {
                            font-size: 5em;
                        }
                    }

                    .content {
                        font-size: 1.5625em;
                        line-height: 1.5em;
                        color: $blueDarkest;
                        font-weight: 700;
                        margin: 0;
                        padding: 0;

                        @media only screen and (max-width: 600px) {
                            font-size: 1.3em;
                        }
                    }
                }
            }
            @media only screen and (max-width: 600px) {
                display:flex;
                flex-direction:column;
                align-items:stretch;
                &:before {
                    height:auto;
                    display:block;
                    flex:auto;
                }
            }
        }
    }

    .decade-1960, [class^="decade-196"], [class*=" decade-196"] {
        background-color: #abeadc !important;
    }

    .decade-1970, [class^="decade-197"], [class*=" decade-197"] {
        background-color: #ffc60b !important;
    }

    .decade-1980, [class^="decade-198"], [class*=" decade-198"] {
        background-color: #f5821f !important;
    }

    .decade-1990, [class^="decade-199"], [class*=" decade-199"] {
        background-color: #8b2283 !important;
    }

    .decade-2000, [class^="decade-200"], [class*=" decade-200"] {
        background-color: #00859c !important;
    }

    .decade-2010, [class^="decade-201"], [class*=" decade-201"] {
        background-color: #e6822b !important;
    }

    .decade-2020, [class^="decade-202"], [class*=" decade-202"] {
        background-color: #abeadc !important;
    }
}
