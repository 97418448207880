%component-highlight-simple {
  .highlight__content {
    @include typeDefault;
    @include typeHeader;
    padding:0;
    background:transparent;
    max-width:none;
    &.component-highlight-bluedark {
      color: $blueDark;
    }
    &.component-highlight-blue,
    &.component-highlight-now {
      color: $blueMedium;
    }
    &.component-highlight-win,
    &.component-highlight-yellow,
    &.component-highlight-historic {
      color: $yellow;
    }
    &.component-highlight-orange,
    &.component-highlight-critical {
      color: $orange;
    }
    &.component-highlight-purple {
      color: $purple;
    }
  }
}

.component-highlight {
  &.component-highlight-simple {
    @extend %component-highlight-simple;
  }
  .highlight__content {
    display: inline-block;
    flex: none;
    padding-top: 0.666em;
    padding-bottom: 0.666em;
    position: relative;
    overflow: visible;
    max-width: calc(100% - $grid-outer-padding);
    @include typeHeaderSmall;
    &.component-highlight-bluedark {
      background: $blueDark;
      @include typeContrast;
    }
    &.component-highlight-blue,
    &.component-highlight-now {
      background: $blueMedium;
      @include typeContrast;
    }
    &.component-highlight-win,
    &.component-highlight-yellow,
    &.component-highlight-historic {
      background: $yellow;
      @include typeAlt;
    }
    &.component-highlight-orange,
    &.component-highlight-critical {
      background: $orange;
      @include typeContrast;
    }
    &.component-highlight-purple {
      background: $purple;
      @include typeContrast;
    }
  }
  .highlight__squiggle {
    position: absolute;
    top: 0.75em;
    height: 3em;
    transform: translateY(-50%);
    display:none;
  }
}
