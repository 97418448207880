$size-pulse: 60;

.component-pulse {
  @extend .component-contrast;
  background: $orange;
  height: em($size-pulse);
  width: em($size-pulse);
  text-align: center;
  border-radius: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  padding-left: 2px;
  position: relative;
  .pulse__content {
    font-size: em($size-pulse * 1.2);
    line-height:1em;
    margin-top:6%;
  }
  .pulse__ring {
    height: 100%;
    width: 100%;
    border: 1px solid $orange;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 50% 50%;
    opacity: 1;
    animation: pulse 1s infinite ease-out;
    pointer-events: none;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
