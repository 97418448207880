$padding-search-field-x: #{2 * $grid-gutter-width};
$padding-search-field-y: #{0.5 * $grid-gutter-width};
$padding-search-field-y-expanded: #{.75 * $grid-gutter-width};
$padding-search-field-y-reduced: #{0.25 * $grid-gutter-width};

.post__section-filters {
  position: relative;
  z-index: 10;
  .scrollmagic-pin-spacer {
    z-index:2;
  }
}

.component-archive-filters {
  transition: font-size 0.3s ease-out;
  background: none;
  &.reduced {
    font-size: 0.85em;
    form#searchform {
      border-width:0 1.5px 1.5px;
      input,
      select {
        padding-top: 4px;
        padding-bottom: 4px;
        font-size: 1em;
      }
      .search__field__wrapper,
      .search__filter {
        padding: $padding-search-field-y-reduced $padding-search-field-x;
        border-right-width:1.5px;
      }
    }
  }
  form#searchform {
    border:3px solid $blueDark;
    border-top:none;
    box-shadow: 0px 2px 6px #00000029;
    @extend .form-alt;
    @extend .component, .component-blue-lighter;
    display: flex;
    input,
    select {
      transition: all 0.3s ease-out;
    }
    label {
      @include typeHeaderXS;
      @include typeAlt;
    }
    .search__field__wrapper,
    .search__filter {
      flex:1;
      width:auto;
      transition: padding 0.3s ease-out;
      border-right: 3px solid $blueDark;
      padding: $padding-search-field-y $padding-search-field-x $padding-search-field-y-expanded;
    }
    .search__field,
    .search__filter select {
      @include typeDefault;
      text-transform: none;
    }
    .search__fields__wrapper,
    .search__actions {
      flex: auto;
    }
    .search__fields__wrapper {
      display:flex;
      flex-wrap:nowrap;
      margin-left: 0;
      margin-right: 0;
    }
    .search__field__wrapper {
      label {
        display: inline-block;
      }
      input {
        width: 100%;
      }
    }
    .search__additional-field {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    .search__filters {
      display:flex;
      flex-wrap:nowrap;
    }
    .search__filter {
      select {
        width: 100%;
      }
    }
    .search__actions {
      position: static;
      flex: none;
      width:calc(20% - 6px);
      #searchsubmit {
        @extend .btn, .btn-sharp;
        position: relative;
        height: 100%;
        width:100%;
        img {
          display:none;
        }
        &:after {
          content:"GO";
          @include typeHeaderMid;
          @include typeContrast;
        }
      }
    }
  }
  @include media-breakpoint-down(xl) {
    &,&.reduced {
      .container-wide {
        padding:0;
      }
      form#searchform {
        border-width:0 0 3px;
        .search__field__wrapper,
        .search__filter {
          padding-left: $padding-search-field-y;
          padding-right: $padding-search-field-y;
        }
        .search__actions { 
          width:auto;
        }
      }
    }
    &.reduced {
      form#searchform {
        border-bottom-width:1.5px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    &,&.reduced {
      form#searchform {
        .search__fields__wrapper {
          flex-wrap:wrap;
        }
        .search__field__wrapper {
          flex:none;
          width:100%;
          border-bottom:3px solid $blueDark;
        }
      }
    }
    &.reduced {
      form#searchform {
        .search__field__wrapper {
          border-bottom:1.5px solid $blueDark;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    &,&.reduced {
      form#searchform {
        .search__field__wrapper {
          border-bottom:none;
        }
      }
    }
  }
}

.search-results {
  form#searchform {
    .search__field__wrapper {
      width:auto;
      flex:auto;
    }
  }
}
