%list-links {
  @extend .list-unstyled;
  li {
    line-height:1.2em;
    margin-bottom:.8em;
  }
}

.list-links {
  @extend %list-links;
}