.component-testimonial {
  padding-top:em(100);
  padding-bottom:em(40);
  margin-top:em(80);
  .container {
    padding-left:#{$grid-outer-padding - 2 * $grid-gutter-width};
    padding-right:#{$grid-outer-padding + 2 * $grid-gutter-width};
    @media screen and (max-width: 800px) {
      padding-left: $grid-outer-padding-mobile;
      padding-right: $grid-outer-padding-mobile;
    }
  }
  .testimonial__layout {
    display:grid;
    grid-template-columns:repeat(4, 1fr);
    grid-auto-rows:auto 1fr;
  }
  .testimonial__primary {
    padding-left:$grid-gutter-width;
    grid-column: 2 / 5;
    grid-row: 1 / 3;
  }
  .testimonial__pre,.testimonial__post {
    padding-right:$grid-gutter-width;
  }
  .testimonial__pre {
    grid-column:1;
    grid-row:1;
    padding-bottom:em(40);
  }
  .testimonial__post {
    grid-column:1;
    grid-row:2;
  }
  h2.testimonial__title {
    .single .post__content &,.page .post__content &,.block-editor-writing-flow & {
      @include typeDefaultXL;
      @include typeAlt;
      margin-top:0;
    }
  }
  .testimonial__content {
    @include typeInContent;
  }
  .testimonial__image {
    width:100%;
    padding-top:100%;
    position:relative;
    border-radius:100%;
    background:$blueLight;
    overflow:hidden;
    margin-top:em(-140);
    @include materialShadow;
    img {
      position:absolute;
      top:50%;
      left:50%;
      height:100%;
      max-width:none;
      width:auto;
      transform:translate(-50%,-50%);
    }
  }
  .testimonial__related {
    border-left: 2em solid $blueLight;
    padding-left: 1em;
    h3 {
      @include typeDefaultLarge;
      @include typeAlt;
    }
  }
  @include media-breakpoint-down(lg) {
    .testimonial__layout {
      grid-template-columns:1fr;
      grid-auto-rows:auto auto auto;
    }
    .testimonial__primary {
      padding-left:0;
      grid-column:1;
      grid-row:2;
    }
    .testimonial__pre,.testimonial__post {
      padding-right:0;
    }
    .testimonial__pre {
      grid-column:1;
      grid-row:1;
    }
    .testimonial__post {
      grid-column:1;
      grid-row:3;
    }
    .testimonial__image {
      width:200px;
      padding-top:200px;
    }
  }
}