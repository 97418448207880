.component-timeline {
  padding: em(87) 0 em(120);
  .timeline__events {
    position:relative;
    &:before {
      content:"";
      position:absolute;
      width:4px;
      background:$blueMedium;
      left:calc(50% - 2px);
      top:0;
      height:100%;
    }
  }
  .timeline__event {
    width:50%;
    height:0;
    overflow:visible;
    position:relative;
    &:last-child {
      height:auto;
      background: $blueLighter;
      width: calc(50% + 2px);
    }
    &.even {
      padding-right:3 * $grid-gutter-width;
      text-align:right;
      &:after {
        left:100%;
        transform:translateX(-50%);
      }
      .timeline__event__description {
        margin-left:20%;
        @include media-breakpoint-down(md) {
          margin-left:0;
        }
      }
    }
    &.odd {
      margin-left:50%;
      padding-left:3 * $grid-gutter-width;
      &:after {
        right:100%;
        transform:translateX(50%);
      }
    }
    &:after {
      content:"";
      height:1.75 * $grid-gutter-width;
      width:1.75 * $grid-gutter-width;
      background:$blueMedium;
      border-radius:100%;
      position:absolute;
      top:-.5em;
    }
    .timeline__event__year {
      @include typeHeaderTimeline;
    }
    .timeline__event__description {
      @include typeDefaultMid;
      max-width:80%;
      @include media-breakpoint-down(md) {
        max-width:100%;
      }
    }
  }
}