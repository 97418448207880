.page-donate {
  header.at-title {
    display:none;
  }
  .wp-container-3 {
    column-gap:em(80);
  }
  #dafdirectdiv {
    &,form {
      width:100%;
      input,select,.dafdirectInputAmount {
        width:100%;
      }
    }
    .whatThis a {
      width:auto !important;
    }
  }
  .at {
    form {
      .label-amount {
        color:$blueDark;
        text-shadow:none;
        &,&:hover {
          > a {
            @extend .btn, .btn-trans, .btn-trans-alt;
          }
        }
        &:hover {
          > a {
            border-color:$blueMedium;
            background:$blueMedium;
          }
        }
        input[type="radio"]:checked {
          + a {
            border-color:$blueMedium;
            background:$blueMedium;
          }
        }
      }
    }
  }
}