.page-staff {
  .post__content {
    padding-top:0;
    padding-bottom:0;
    h2 {
      margin-top:0;
    }
    .component {
      .component__title,.subsection__title {
        @include typeHeaderLarge;
        margin-bottom:1.25em;
      }
    }
    .component-executive-team {
      .subsection__title {
        @include typeContrast;
      }
    }
  }
}

.component-executive-team, .component-staff {
  &.component-contrast {
    padding-top:em(125);
    padding-bottom:em(149);
    .subsection__title {
      @include typeContrast;
    }
    .post__subsection__content {
      [class*=col] {
        &:last-child {
          .card-default {
            @include media-breakpoint-down(lg) {
              margin-bottom:0;
            }
          }
        }
      }
    }
    .post__preview {
      margin-top:em(-77);
      @include media-breakpoint-down(lg) {
        margin-bottom:em(154);
      }
    }
  }
  .list-staff {
    margin-bottom:0;
    padding-left:.5 * $grid-gutter-width;
    padding-right:.5 * $grid-gutter-width;
    li {
      padding-left:4px;
      padding-right:4px;
      padding-bottom:8px;
      height:100%;
    }
    .card {
      @include materialShadowReduced;
      margin:0;
    }
  }
  .post__subsection__header {
    .subsection__title {
      margin-bottom:.75em;
    }
  }
}

.component-staff {
  padding-top:em(125);
  .post__subsection__header {
    p {
      &:last-child {
        margin-bottom:0;
      }
      margin-bottom:1em;
      margin-top:0;
    }
  }
  + .component:not(.component-staff) {
    margin-top:em(149);
  }
}

.component-board {
  padding-top:em(121);
  padding-bottom:em(130);
  .subsection__title {
    max-width:em(200,60);
  }
  h3 {
    @include typeDefault;
    font-weight:bold;
    margin-bottom:.5em;
  }
}