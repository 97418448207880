$squiggle-pos: bottom $grid-gutter-width right math.div($grid-gutter-width, 2);

.component-motto {
  background-color: transparent;
  background-image: /*url("images/component-squiggle-alt.svg"),*/
    url("images/component-ppp-stylized.svg"),
    linear-gradient(180deg, $yellow 0%, $pink 100%);
  background-position: /*$squiggle-pos,*/ calc(50% - 474px) em(108), 0 0;
  background-repeat: no-repeat;
  .motto__content {
    padding-top: em(108);
    padding-bottom: em(134);
    align-items: center;
  }
  .motto__headline {
    @include typeHeaderSuper;
    @include typeContrast;
    visibility: hidden;
  }
  .motto__description {
    @include typeDefaultLarger;
    @include typeAlt;
  }
  @media screen and (max-width: $container-with-padding) {
    background-position: /*$squiggle-pos,*/ right 50vw top em(108), 0 0;
  }
  @include media-breakpoint-down(lg) {
    background-position: /*$squiggle-pos,*/
      right calc(40vw + $grid-gutter-width) top em(94), 0 0;
    .motto__content {
      align-items: flex-start;
    }
    .motto__description {
      @include typeDefaultUp;
      @include typeAlt;
    }
  }
  @include media-breakpoint-down(md) {
    background-position: /*$squiggle-pos,*/ right calc(50vw - 190px) bottom, 0 0;
    .motto__headline {
      font-size: 1em;
      height: 20em;
    }
    .motto__description {
      order: 0;
      margin-bottom: em(40, 16);
      padding-left: 22.5%;
      padding-right: 22.5%;
      text-align:center;
    }
    .motto__motto {
      order: 1;
    }
  }
  @include media-breakpoint-down(sm) {
    background-position: /*$squiggle-pos,*/ right 18vw bottom, 0 0;
    .motto__headline {
      height: 10em;
    }
    .motto__description {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
      margin-bottom: em(80, 16);
    }
  }
}
