$light: #ffffff;
$dark: #000000;

$blueLighter: #e5f8f4;
$blueLight: #abeadc;
$blueGrayLight: #f5fbf9;
$blueGray: #9fd5c9;
$blueGrayMedium: #63b0bb;
$blueMedium: #00859c;
$blueMediumAlt: #006677;
$blueDark: #02353c;
$blueDarkest: #02171D;

$grayBG: #f2f2f2;
$grayLight: #00000029;
$grayMedium: #cbcbcb;

$orange: #f5821f;
$yellow: #ffc60b;
$purple: #8B2283;
$pink: #feb4ab;
$magenta: #af3c90;

$card-shadow: 2px 5px 6px $grayLight;
$card-shadow-active: 4px 10px 12px $grayLight;

$card-shadow-reduced: 1px 2.5px 3px $grayLight;
$card-shadow-reduced-active: 2px 5px 6px $grayLight;

@mixin materialShadow($hover: false) {
  box-shadow: $card-shadow;
  @if $hover {
    &:hover {
      box-shadow: $card-shadow-active;
    }
  }
}

@mixin materialShadowReduced($hover: false) {
  box-shadow: $card-shadow;
  @if $hover {
    &:hover {
      box-shadow: $card-shadow-active;
    }
  }
}
