.nav-social {
  &.nav__menu {
    display: flex;
  }
  .nav__menu-item {
    &:last-child {
      .nav-link {
        &:before {
          margin-right: 0;
        }
      }
    }
    span {
      display: none;
    }
    .nav-link {
      &:before {
        content: "";
        display: inline-block;
        height: em(33);
        width: em(33);
        margin-right: em(17);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    $services: "facebook", "twitter", "tiktok";

    @each $service in $services {
      &.nav__menu__item-#{$service} {
        .nav-link {
          &:before {
            background-image: url("images/icon-#{$service}-contrast.svg");
          }
        }
      }
    }
  }
}
