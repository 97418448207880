form#searchform {
  position: relative;
  .search__field {
    width: 100%;
  }
  .search__actions {
    position: absolute;
    top: 0.25em;
    right: 0;
    text-align: right;
    button,
    .btn {
      img {
        height: 1.333em;
      }
    }
    .icon-search {
      display: none;
    }
  }
}
