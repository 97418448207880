.post-type-archive-news {
  .post__section-archive {
    padding-top:0;
    padding-bottom:0;
    .post__subsection-featured {
      padding-top:em(86);
    }
    .col-news,.col-press_release {
      .container-breakout {
        padding-top:em(86);
        padding-bottom:0;
        height:100%;
      }
    }
    .col-news {
      .container-breakout {
        background:$grayBG;
        padding-left:em(50);
      }
    }
    .col-press_release {
      .container-breakout {
        padding-right:em(50);
      }
    }
    .addtl_results {
      margin-bottom:0;
    }
    h2 {
      @include typeDefaultLarger;
      @include typeAlt;
      font-weight:bold;
    }
    .post__preview {
      border-bottom:1px solid $blueMedium;
      margin-bottom:0;
      &.post__preview-featured {
        @extend .component, .component-contrast;
        background:$purple;
        margin-bottom:em(30);
        border:none;
        a {
          display:block;
          text-decoration:none;
          padding:em(32) em(26);
          overflow:auto;
          &:hover {
            text-decoration:underline;
            .post__pre-headline {
              text-decoration:none;
            }
          }
        }
        .post__image {
          height:em(80);
          width:em(80);
          background:transparent center center no-repeat;
          background-size:cover;
          float:left;
          margin:0 em(20) 0 0;
          padding:0;
        }
        .post__pre-headline {
          text-decoration:none;
          margin-top:.5em;
        }
        .post__title {
          @include typeDefault;
          @include typeContrast;
          font-weight:bold;
          margin:0;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      .col-news,.col-press_release {
        .container-breakout {
          @include breakout-cancel;
        }
      }
    }
  }
}