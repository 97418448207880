.nav-site {
    .nav-item {
        @include typeHeaderNav;

        &.nav__menu__item-donate,
        &.nav__menu__item-join_us {
            margin-left: em(7.5, 22);
        }

        &.nav__menu__item-donate {
            .nav-link {
                @extend %link-bright-alt;
            }
        }

        &.nav__menu__item-join_us {
            .nav-link {
                @extend %link-dark;
            }
        }

        &.nav__menu__item-take_action {
            .nav-link {
                @extend %link-bright;
            }
        }

        &.current-menu-ancestor, &.current-menu-item { 
            .nav-link {
                @extend %link-blue-dark;
            }
        }

        .nav-link {
            @extend %link-solid;
            @extend %link-blue;
        }

        .nav-item {
            .nav-link {
                @include typeLink;
                @include typeLinkAlt;
            }
        }
    }
}

.navbar-collapse.show,
.navbar-collapse.collapsing {
  .nav-site {
    .nav-item {
      &.nav__menu__item-donate,
      &.nav__menu__item-join_us {
        margin-left: 0;
      }
    }
  }
  .navbar-secondary {
    flex-direction: column;
    align-items: flex-end;
  }
}
