.component-hp-impacts {
  padding: em(95) 0;
  .impacts__title {
    @include typeHeaderMid;
    @include typeContrast;
    margin-bottom: 3em;
  }
  .impacts__content {
    flex-direction: column;
    max-height: 920px;
    flex-wrap: wrap;
    width: calc(33.333% + #{$grid-gutter-width} / 3);
    .post__preview,
    .card {
      min-height: 150px;
      margin-bottom: $grid-gutter-width;
      &.card-impact {
        background-image: url("images/fg-person.svg");
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: 40% auto;
        border: 2px solid $light;
        h3 {
          @include typeHeaderMid;
          @include typeContrast;
          margin-bottom: 1em;
        }
        .card__wrapper {
          width: 60%;
          margin-left: 40%;
          padding-left: 0;
          padding-bottom: #{1.5 * $grid-gutter-width};
        }
        @include media-breakpoint-down(xl) {
          background-size: 30% auto;
          .card__content {
            width: 100%;
            margin-left: 0;
            padding-left: em(24);
          }
          .nav-social {
            padding-left: 50%;
          }
        }
        @include media-breakpoint-down(lg) {
          .card__content {
            width: 65%;
            margin-left: 35%;
            padding-left: 0;
          }
          .nav-social {
            padding-left: 0;
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      width: calc(50% + #{$grid-gutter-width} / 2);
      max-height: 1350px;
    }
    @include media-breakpoint-down(sm) {
      width: auto;
      max-height: none !important;
    }
  }
  .impacts__actions {
    margin: em(60) 0 0;
  }
}
