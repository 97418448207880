/*.nav-our_impacts {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto auto auto auto auto auto;
  margin-bottom:em(-42);
  .nav-super__group-topics,
  .nav-super__group-strategies,
  .nav-super__group-wherewework {
    padding-right: $grid-gutter-width;
    grid-column: 1;
  }
  .nav-super__group-topics,
  .nav-super__group-strategies {
    margin-bottom:$grid-gutter-width;
  }
  .nav-super__group-topics {
    grid-row: 1/span 2;
  }
  .nav-super__group-strategies {
    grid-row: 3/span 2;
  }
  .nav-super__group-wherewework {
    grid-row: 5/span 2;
    padding-bottom:$grid-gutter-width;
    img {
      max-width: 245px;
    }
  }
  .nav-super__group-impacts {
    grid-row: 1 / span 6;
    grid-column: 2;
    display:flex;
    flex-direction:column;
    height:100%;
    .group-impacts-featured {
      flex:none;
      [class*=col-] {
        &:first-child {
          padding-left:.5 * $grid-gutter-width;
        }
        &:last-child {
          padding-right:.5 * $grid-gutter-width;
        }
        padding-left:.25 * $grid-gutter-width;
        padding-right:.25 * $grid-gutter-width;
      }
      .post__preview {
        height:100%;
        margin-bottom:0;
      }
    }
    .group-impacts-other {
      margin-top: em(52);
      flex:auto;
      display:flex;
      flex-direction:column;
      h2,.group__wrapper {
        padding-left: $grid-gutter-width;
        border-left:2px solid transparent;
      }
      h2,.group__footer {
        flex:none;
      }
      .group__wrapper {
        flex:auto;
        border-color: $blueMedium;
        columns:2;
      }
      .group__footer {
        background: $blueLight;
        padding: em(12) $grid-gutter-width;
        border-left: 1px solid transparent;
        margin-right: -2 * $grid-gutter-width;
        margin-top: $grid-gutter-width;
        a,i {
          @include typeDefault;
          color: $blueMedium;
        }
        a {
          text-decoration:none;
          &:hover {
            text-decoration:underline;
          }
        }
      }
      .post__preview {
        line-height: 1.2em;
        margin-bottom: 1em;
        &:hover {
          box-shadow: none;
          transform: none;
        }
        .post__title {
          @include typeDefault;
          color: $blueDark;
          line-height: 1.2em;
        }
      }
    }
  }
}*/

.nav__menu .nav__menu-item .dropdown-menu {
  .nav-our_impacts {
    .nav-super__group-topics,
    .nav-super__group-strategies,
    .nav-super__group-wherewework {
      .nav__menu-item {
        @include typeDefault;
      }
    }  
    .nav__menu-item {
      img {
        max-width:100%;
      }
    }
  }
}