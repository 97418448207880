.single-impact {
  .post__status {
    margin-top: -3em;
    position: relative;
    z-index: 2;
    .badge {
      @include typeHeaderXS;
    }
  }
  
  @include media-breakpoint-down(lg) {
    .component-hero-with-cta {
      .hero__content {
        padding-bottom:em(120);
      }
    }
    .post__status {
      margin-top: -6em;
    }
  }
}
