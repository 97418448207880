%page-proactive-policy {
  .component-hero-with-cta {
    .hero__headline {
      max-width:66.666%;
    }
  }

  .post__section-main {
    .post__resource__inner {
      padding:em(40) em(48) em(80);
      background:$blueMedium;
      h2 {
        @include typeHeaderSmall;
        color:$light;
        margin-bottom:1em;
      }
    }
  }

  .post__section-archive {
    padding: em(60) 0 em(120);
    .component-archive-filters {
      margin-bottom: em(120);
      margin-top: em(-60);
    }
  }

  .addtl_results__control {
    .btn {
      display: block;
      margin: em(30) auto em(60);

      &:hover {
        background: rgba($light, 0.5);
        border-color: $light;
      }
    }
  }

  .addtl_results {
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.33s ease-in-out;
  }
}

.page-proactive-policy {
  @extend %page-proactive-policy;
}
