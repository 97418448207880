$hero-content-width: 66.666%;

.component-hero-with-cta {
  height: auto;
  text-align: left;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-color: $blueDark;
  &.hero-reduced,&.hero-interior {
    .hero__content {
      .hero__content__inner {
        max-width: $hero-content-width;
        @include media-breakpoint-down(lg) {
          max-width: none;
        }
      }
    }
  }
  &.hero-reduced {
    text-align: left;
    .hero__content {
      z-index: 5;
      pointer-events:none;
    }
    .hero__headline {
      @include typeHeaderMid;
    }
  }
  &.hero-interior {
    min-height:em(523);
    .hero__headline {
      @include typeHeaderLarge;
      margin-bottom:0;
      .hero__headline__link {
        background:none;
        padding:0;
      }
    }
  }
  &,.hero__content {
    display: flex;
    flex-direction: column;
  }
  .hero__content {
    position: relative;
    padding: em(300) 0 em(60);
    width: 100%;
    margin: 0;
    z-index: 2;
    justify-content: flex-end;
    flex:auto;
    @include media-breakpoint-down(sm) {
      padding-bottom: em(30);
    }
  }
  .bg-screen {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
    &.bg-screen-top {
      height: 25%;
      top: 0;
      background: transparent
        linear-gradient(180deg, #000000 0%, #00000000 100%) 0% 0% no-repeat
        padding-box;
      opacity: 0.4;
    }
    &.bg-screen-bottom {
      height: 75%;
      top: 25%;
      background: transparent linear-gradient(0deg, #000000 0%, #54545400 100%)
        0% 0% no-repeat padding-box;
      opacity: 0.8;
    }
  }
  .hero__headline {
    @include typeHeaderSuperLarge;
    @include typeContrast;
    margin-bottom:0;
    .hero__pre-headline,.hero__post-headline {
      display: block;
      line-height: 1.2em;
    }
    .hero__pre-headline {
      @include typeDefault;
      @include typeContrast;
      font-size: .4em;
      margin-bottom: 0.3em;
    }
    .hero__post-headline {
      @include typeDefault;
      @include typeContrast;
      font-size: .66em;
      margin-top: 0.2em;
    }
    .hero__headline__link {
      background:$blueMedium;
      color:inherit;
      text-decoration:none;
      transition:background .33s ease-out;
      box-decoration-break:clone;
      padding:0 1em;
      &:hover {
        background:darken($blueMedium,10%);
      }
      @include media-breakpoint-down(sm) {
        padding:0 .2em;
      }
    }
  }
  .page__breadcrumbs {
    margin-bottom: 2.4em;
  }
  .hero__cta,.hero__description,.hero__meta {
    margin-top:2em;
    p {
      &:last-child {
        margin-bottom:0;
      }
    }
  }
  .hero__cta {
    pointer-events:all;
  }

  .hero__meta {
    @include typeDefault;
    @include typeContrast;
    > div {
      display:inline-block;
      margin: 0 3em 0 0;
      height:1.2em;
    }
  }
  .hero__description {
    @include typeDefaultMid;
    @include typeContrast;
  }
}
