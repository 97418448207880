.nirh2022 {
  .ig_popup.ig_inspire {

    margin:0 auto !important;
    width:90%;
    max-height:90%;

    .ig_data {
      padding: em(90) calc(50% + em(40)) em(90) em(64);
      background-size: 50% auto;
      background-position: right center;
      background-repeat: no-repeat;
    }
    .ig_headline {
      @include typeHeader;
      @include typeHeaderMid;
      @include typeAlt;
      padding:0;
      text-align:left;
      margin:0 0 1.5em;
    }
    .ig_content {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        overflow:hidden;
      }
    }
    .ig_message {
      @include typeDefault;
      @include typeAlt;
      padding:0;
      margin:0;
      text-align:left;
    }
    .ig_button {
      &:hover {
        box-shadow:none;
        border-top:1px solid $blueMedium;
        color:$light !important;
      }
      @extend .btn, .btn-trans, .btn-trans-alt, .btn-large;
      margin:3em 0 0 0;
      color:$blueDarkest !important;
      width:auto !important;
    }
    .ig_close {
      background:transparent url("images/icon-close.svg") center center no-repeat;
      background-size:contain;
      opacity:1;
      height:em(24);
      width:em(24);
      top:em(22);
      right:em(22);
      cursor:pointer;
    }

    @media only screen and (min-width: 1440px) {
      max-width:860px;
    }

    @media screen and (max-width: 767px) {
      .ig_data {
        padding: em(245,13) em(30,13) em(45,13);
      }
      .ig_content {
        &:after {
          top: 0;
          width: 100%;
          height: em(200,13);
        }
      }
      .ig_headline {
        margin-bottom:.75em;
      }
      .ig_button {
        margin-top:1.5em;
      }
    }
  }
}