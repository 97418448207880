$padding-cluster-y: em(24);
$padding-cluster-y-reduced: em(10);
$padding-cluster-x: em(24);

$padding-simple-top: em(36);
$padding-simple-bottom: em(30);
$padding-simple-x: em(28);

$padding-cover-x: 75;
$padding-cover-x-mobile: 30;

$padding-default-x: 6.6%;
$padding-default-y: 5.8%;

.post__preview {
  @extend %card-interactive;
  .component-contrast & {
    @include typeDefault;
    a {
      @include typeLink;
      &,
      &:hover {
        text-decoration: none;
        color: inherit;
      }
    }
  }
  &.card-default {
    position:relative;
    padding-top:95%;
    margin-bottom:$grid-gutter-width;
    &.has-status {
      .post__meta {
        .component-pills {
          .pill-date {
            display:inline;
          }
        }
      }
    }
    .card__wrapper {
      position:absolute;
      top:0;
      left:0;
      align-items:stretch;
      width:100%;
    }
    .card__image {
      flex:auto;
      background:$blueMedium;
      .card__section__inner,a,.post__image {
        height:100%;
      }
      a {
        display:block;
        width:100%;
      }
      .post__image {
        padding-top:0;
      }
    }
    .card__header {
      position:absolute;
      width:100%;
      top:em(12);
      left:0;
    }
    .card__content__wrapper {
      padding:$padding-default-y $padding-default-x;
      flex:none;
      display: flex;
      flex-direction: column;
      align-items:stretch;
      min-height:8em;
    }
    .component-pills {
      @extend .pills-plain;
    }
    .post__description {
      display:none;
    }
    .post__title {
      @include typeHeaderCardDefault;
      margin-top:0;
    }
    .post__meta {
      margin:0 !important;
      .component-pills {
        .pill-date {
          display:none;
        }
      }
    }
    .component-highlight {
      .highlight__content {
        @include typeHeaderXS;
        padding-left: $padding-default-x;
        padding-right: $padding-default-x;
        border-radius:0 4px 4px 0;
        background:$blueDark;
        color:$light;
      }
    }
    @include media-breakpoint-down(xl) {
      .card__content__wrapper,.post__meta {
        min-height:0;
      }
    }
  }
  &.card-cluster {
    &.compact {
      &.has-status {
        .card__content {
          .card__section__inner {
            padding-top:$padding-cluster-y-reduced;
          }
        }
      }
      .card__wrapper {
        flex-direction:row;
        flex-wrap:wrap;
      }
      .card__section {
        width:100%;
        flex:none;
      }
      .card__image,.card__content__wrapper {
        width: 50%;
        flex:.5;
      }
      .card__image {
        .card__section__inner {
          padding-right:5%;
        }
      }
      .card__content,.card__meta {
        .card__section__inner {
          padding-left:5%;
        }
      }
      .card__content {
        .card__section__inner {
          padding-top:$padding-cluster-y;
        }
      }
      .post__description {
        display:none;
      }
      .post__image {
        padding-top:100%;
      }
    }
    &.has-status {
      .card__header {
        display:block;
      }
      .card__image {
        .card__section__inner {
          padding-top:$padding-cluster-y-reduced;
        }
      }
    }
    .card__section {
      .card__section__inner {
        padding-left:$padding-cluster-x;
        padding-right:$padding-cluster-x;
      }
    }
    .card__header {
      order:1;
      display:none;
      .card__section__inner {
        padding-left:0;
        padding-right:0;
      }
    }
    .card__image,.card__content {
      margin-bottom:1em;
    }
    .card__image {
      order:2;
      .card__section__inner {
        padding-top:$padding-cluster-y;
      }
    }
    .card__content__wrapper {
      order:3;
    }
    .card__content {
      margin-bottom:1em;
      @include typeDefaultLineReduced;
    }
    .card__meta {
      .card__section__inner {
        padding-bottom:$padding-cluster-y;
      }
    }
    .post__title,.post__description,.post__description__content {
      display: inline;
    }
    .post__date {
      font-style: italic;
      margin: 0.5em 0;
    }
    .component-highlight {
      .highlight__content {
        @include typeHeaderXS;
        padding-left: em(24, 16);
        padding-right: em(24, 16);
        width: 100%;
        max-width: none;
        margin: 0;
      }
    }
  }
  &.card-simple {
    box-shadow: none;
    border: 2px solid $blueMedium;
    .card__wrapper {
      padding:$padding-simple-top $padding-simple-x $padding-simple-bottom;
    }
    .card__image {
      display:none;
    }
    .card__header {
      .component-highlight {
        @extend %component-highlight-simple;
      }
      margin-bottom:.5em;
    }
    .card__content__wrapper {
      flex:auto;
      display:flex;
      flex-direction:column;
    }
    .card__content {
      flex:auto;
      .post__title {
        @include typeDefaultMid;
        @include typeDefaultLineReduced;
        color:$blueDark;
        margin-bottom:2em;
      }
    }
    .post__description {
      display:none;
      margin-bottom:em(40);
    }
    .component-pills {
      @extend .pills-plain;
    }
  }
  &.card-cover {
    background:$blueMedium;
    &.compact {
      min-height: em(490);
      .post__description {
        display:none;
      }
      .card__header {
        padding-top: em(21);
      }
    }
    &.expanded {
      min-height: em(520);
      .card__header {
        margin-top: em(32);
      }
      .card__content {
        max-width:calc(#{em(470)} + #{2 * em($padding-cover-x)});
        margin-bottom:em(80);
        @include media-breakpoint-down(lg) {
          max-width:none;
        }
      }
    } 
    &.has-status {
      .post__meta {
        .component-pills {
          .pill-date {
            display:inline;
          }
        }
      }
    }
    &:hover {
      .card__meta {
        background:$purple;
        color:$light;
        border-color:$purple;
      }
      .component-pills {
        .badge,.pill,.rounded-pill {
          color:$light !important;
        }
      }
    }
    @extend .component, .component-contrast;
    margin-bottom: $grid-gutter-width;
    .card__content__wrapper {
      align-items:stretch;
    }
    .card__section {
      position:relative;
      z-index:2;
    }
    .card__section__inner {
      padding-left:em($padding-cover-x);
      padding-right:em($padding-cover-x);
      @include media-breakpoint-down(xl) {
        padding-left:em($padding-cover-x-mobile);
        padding-right:em($padding-cover-x-mobile);
      }
      @include media-breakpoint-down(lg) {
        padding-left:em($padding-cover-x);
        padding-right:em($padding-cover-x);
      }
      @include media-breakpoint-down(md) {
        padding-left:em($padding-cover-x-mobile);
        padding-right:em($padding-cover-x-mobile);
      }
    }
    .card__image {
      position:absolute;
      top:0;
      left:0;
      height:100%;
      width:100%;
      z-index:1;
      filter: brightness(0.5);
      .card__section__inner,.post__image,a {
        width: 100%;
        height: 100%;
        padding: 0;
      }
      a {
        display:block;
      }
    }
    .card__header,.card__content__wrapper {
      pointer-events:none;
    }
    .card__header {
      margin-bottom:em(24);
      .card__section__inner {
        padding-left:0;
        padding-right:0;
      }
    }
    .card__content__wrapper {
      flex:auto;
      display: flex;
      flex-direction: column;
    }
    .card__content {
      flex:auto;
    }
    .card__meta {
      flex:none;
      background:$light;
      border:1px solid $blueDark;
      border-top-width:0;
      transition:all .3s ease;
      pointer-events:all;
      min-height: 5.6em;
      .component-pills {
        padding-top:1em;
        padding-bottom:2em;
        .pill-date {
          display:none;
        }
      }
    }
    .post__title {
      @include typeHeaderMid;
      color: $light;
    }
    .post__date {
      font-size: 1em;
      margin: 0;
    }
    .component-pills {
      @extend .pills-plain;
    }
    .highlight__content,
    .post__date {
      font-style: normal;
    }
    .highlight__content {
      @include typeHeaderSmall;
      @include typeContrast;
      max-width:none;
      background: $blueDark;
      border-radius: 0 4px 4px 0;
      width: auto;
      padding: 1em 1.2em 1em em($padding-cover-x,24);
      text-align: left;
      &.component-highlight-historic,
      &.component-highlight-now,
      &.component-highlight-critical {
        color: $light;
      }
      &.component-highlight-critical {
        background: none;
        padding-top:0;
        padding-bottom:0;
        display:block;
      }
      @include media-breakpoint-down(xl) {
        padding-left: em($padding-cover-x-mobile,24);
      }
      @include media-breakpoint-down(lg) {
        padding-left: em($padding-cover-x,24);
      }
      @include media-breakpoint-down(md) {
        padding-left: em($padding-cover-x-mobile,18);
      }
    }
    .post__date {
      font-size:1em;
    }
  }
  a {
    &,
    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }
  .card__wrapper {
    display:flex;
    flex-direction:column;
    padding:0;
  }
  .post__image {
    width: 100%;
    padding-top: 50%;
    background: transparent center center no-repeat;
    background-size: cover;
  }
  &.post__preview-plain {
    box-shadow:none;
  }
}

.post__preview-post,.post__preview-event {
  &.cover {
    .card__content {
      background: $blueDark;
    }
  }
}

