.post__preview-resource {
  &.card-default {
    .card__image {
      min-height:0;
    }
    .card__content {
      .card__section__inner {
        min-height:10.8em;
        display: flex;
        flex-direction: column;
        @include media-breakpoint-down(lg) {
          min-height:0;
        }
      }
    }
    .card__title {
      flex:auto;
    }
    .post__series,.post__date {
      flex:none;
      min-height:1.2em;
    }
    .post__series {
      line-height:1.2em;
      margin-bottom:2em;
      margin-top:-.4em;
    }
    .post__date {
      margin-top:2em;
      margin-bottom:-.666em;
    }
  }
}