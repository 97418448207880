.post__preview-job {
  &,.page .post__content & {
    &.card {
      @extend .component, .component-blue-dark, .component-contrast;
    }
    .card__link {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
    .card__content__wrapper {
      padding:em(36) em(38) em(43);
      display:flex;
      flex-direction:column;
      justify-content:space-between;
      height:100%;
    }
    .post__title {
      @include typeHeaderCardMid;
      @include typeContrast;
      margin-bottom:3.5em;
      margin-top:0;
    }
    .post__description {
      margin-bottom:em(60);
    }
    .post__action {
      a {
        font-weight:bold;
        text-decoration:underline;
      }
    }


    &.card-trans {
      background:transparent;
      color:$blueDark;
      border:3px solid $blueDark;
      .post__title {
        @include typeAlt;
        &:after {
          content:"";
          background:transparent url(images/icon-envelope.svg) top left no-repeat;
          display:block;
          margin-top:2em;
          width:em(204);
          max-width:80%;
          height:auto;
          padding-top:50%;
          background-size:contain;
        }
      }
    }
  }
}