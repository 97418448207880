#site-header {
  position: absolute;
  width: 100%;
  z-index: 100;
  padding-top: em(26);
  .branding-header {
    display: block;
    img {
      height: em(90);
    }
  }
  .navbar-toggler {
    @include typeHeaderNav;
    @include typeContrast;
    box-shadow: none;
    border-radius: 1px;
  }
  .navbar-collapse {
    flex-direction: column;
    align-items: flex-end;
  }
  .navbar-site {
    justify-content: space-between;
    padding: 0;
  }
  .navbar-secondary {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: em(26);
  }
  .nav-social {
    .nav__menu-item {
      .nav-link {
        &:before {
          height: em(26);
          width: em(26);
        }
      }
    }
  }
  form#searchform {
    .search__field-wrapper,
    .search__additional-field,
    #searchreset {
      display: none;
    }
    .search__field__wrapper {
      label {
        display: none;
      }
    }
    margin-right: 2em;
    input[type="text"] {
      display: none;
    }
    .search__actions {
      position: static;
    }
    button,
    .btn {
      display: flex;
      color: $light;
      width: auto;
      height: 1.333em;
      align-items: center;
      background: none;
      border: none;
      padding: 0;
      @include typeHeaderNavReduced;
      img {
        margin-right: 0.25em;
      }
      &:after {
        content: "Search";
      }
      &:hover {
        &:after {
          text-decoration: underline;
        }
      }
    }
  }
  @include media-breakpoint-down(xl) {
    .container {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  @include media-breakpoint-up(lg) {
    .navbar-mobile {
      display:none;
    }
  }
  @include media-breakpoint-down(lg) {
    padding-top:0;
    .container {
      padding-right:0;
    }
    .navbar-site {
      justify-content:flex-start;
      align-items: stretch;
    }
    .navbar-mobile form#searchform button,.navbar-mobile .nav-site-mobile .nav-link,.navbar-toggler__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .navbar-brand {
      padding-top:em(30);
      padding-bottom:em(20);
    }
    .navbar-mobile {
      display:flex;
      margin-left:auto;
      flex:none;
      form#searchform {
        margin-right:0;
        padding-left: 4em;
        padding-right: 4em;
        .search__actions,button {
          height: 100%;
          width: 100%;
        }
        button {
          justify-content: center;
          &:after {
            content:none;
          }
          img {
            height: 2em;
          }
        }
      }
      .nav-site-mobile {
        .nav-item {
          height:100%;
          @include typeHeaderNav;
        }
        .nav-link {
          background:$purple;
          height:100%;
          width:100%;
          padding-left: 1.5em;
          padding-right: 1.5em;
          white-space: nowrap;
        }
      }
    }
    .branding-header {
      img {
        height:em(91);
      }
    }
    .navbar-toggler__wrapper {
      flex:none;
      background:$blueMedium;
    }
    .navbar-toggler {
      height: 100%;
      width: 100%;
      display: block;
      padding:1em 1.5em;
      font-size:1em;
      .bi {
        font-size: em(90);
        width: 1em;
        height: 1em;
      }
    }
    .navbar-collapse.show,
    .navbar-collapse.collapsing {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      width: calc(100% + #{$grid-gutter-width});
      margin-left: -1 * $grid-gutter-width;
      height: calc(100vh - #{em(146)});
      background: $light;
      @include media-breakpoint-down(md) {
        height: calc(100vh - #{em(77)});
      }
      form#searchform,.nav__menu__item-join_us{
        display:none;
      }
      .navbar-primary,
      .navbar-secondary {
        width:100%;
      }
      .navbar-primary {
        .nav-item {
          &:first-child {
            .nav-link {
              padding-top:1em;
            }
          }
          &.nav__menu__item-who_we_are {
            .dropdown-menu {
              display:block;
              max-height: none;
              background: $blueMedium;
              box-shadow: none;
              padding: 1em 40px 0;
              .dropdown-item {
                @include typeLinkContrast;
              }
            }
          }
          &.nav__menu__item-get_resources {
            .nav-link {
              padding-bottom:1em;
            }
          }
          .nav-link {
            padding:.5em $grid-gutter-width;
          }
        }
      }
      .navbar-secondary {
        align-items: flex-start;
        background: #fff;
        padding: 0;
        color: $blueDarkest;
        .nav__menu {
          width:100%;
        }
        .nav-item {
          &:first-child {
            .nav-link {
              padding-top:1em;
            }
          }
          .nav-link {
            @include typeAlt;
            padding:.5em $grid-gutter-width;
            margin: 0;
          }
        }
        @include media-breakpoint-down(lg) {
          order:2;
        }
      }
      .nav-social {
        .nav-item {
          &:first-child {
            .nav-link {
              padding-left:em(30);
            }
          }
          &:last-child {
            .nav-link {
              padding-right:em(30);
            }
          }
          .nav-link {
            padding:1em 0;
            &,
            &:before {
              filter: brightness(0);
            }
          }
        }
      }
      .dropdown-menu {
        display: none;
      }
    }
  }
  @media screen and (max-width:700px) {
    .navbar-brand {
      padding-top:em(25);
    }
    .branding-header {
      img {
        height:em(47);
      }
    }
    .navbar-toggler {
      padding: 0.5em;
      .bi {
        font-size:4em;
      }
    }
    .navbar-mobile {
      form#searchform {
        padding-left: 1em;
        padding-right: 1em;
      }
    }
    .navbar-collapse.show,
    .navbar-collapse.collapsing {
      .navbar-primary,
      .navbar-secondary {
        min-width: 100%;
      }
    }
  }
  @media screen and (max-width:400px) {
    .navbar-mobile {
      form#searchform {
        display:none;
      }
    }
  }
}
