.single,
.page {
  .post__content {
    padding-top: 2*$grid-gutter-width;
    padding-bottom: 2*$grid-gutter-width;
  }
  .post__content__issues {
    padding: 1.5em 0;
    margin-top: em(-60);
    margin-bottom: 2em;
    display: flex;
    align-items: center;
    strong {
      background: $purple;
      @include typeContrast;
      padding: 0.6em 0.5em;
      font-weight: normal;
      margin-right: 0.5em;
      flex: none;
    }
    .post__content__issues__issues {
      a {
        display: inline-block;
        margin-right: 1em;
      }
    }
    @include media-breakpoint-down(lg) {
      align-items:flex-start;
      .post__content__issues__issues {
        a {
          &:first-child {
            margin-top:.6em;
          }
        }
      }
    }
  }
  .post__content,.post__section-related {
    h2 {
      @include typeHeaderInContent;
      margin-top:2 * $grid-gutter-width;
      margin-bottom: 1.36em;
    }
    h3,h4,h5,h6 {
      margin-top: 1em;
      margin-bottom: 1.36em;
    }
    > h2:first-child {
      @include typeDefaultXL;
      @include typeAlt;
    }
    p {
      + details {
        margin-top:-1.4em;
      }
    }
    details {
      &.open,&[open] {
        summary {
          margin-bottom:.7em;
        }
      }
      summary {
        &::marker {
          font-size:.8em;
        }
        font-weight:bold;
      }
    }
  }
  .post__read-more {
    text-align:center;
    padding:2 * $grid-gutter-width 0;
  }
  .post__with-sidebar {
    position: relative;
    z-index: 2;
    .post__section-main .row {
      flex-wrap: nowrap;
    }
    .post__content {
      padding-top: em(60);
      padding-bottom: em(60);
      padding-right: 4 * $grid-gutter-width;
    }
    .post__resource,
    .post__meta {
      margin: em(-90) 0 em(180);
    }
    .post__resource {
      .post__preview {
        @include materialShadow;
      }
    }
    .post__meta {
      h2 {
        @include typeHeaderSmall;
        @include typeAlt;
        margin-bottom: 1em;
      }
      .card {
        border: 4px solid $blueMedium;
        .card__content {
          padding: 2em 3em;
        }
      }
      .btn-dark {
        display:block;
        margin-bottom:2em;
      }
      .post__meta__relateds {
        .post__meta__related {
          line-height:1.2em;
          padding-bottom: 1em;
          margin-bottom: 2em;
          border-bottom: 1px solid $dark;
          display: flex;
          align-items: flex-start;
          &:last-child {
            margin-bottom: 0;
            border-bottom: none;
          }
          &.post__meta__related-dates,
          &.post__meta__related-locations,
          &.post__meta__related-location {
            label {
              white-space:nowrap;
              &:after {
                content: ":";
              }
            }
          }
          &.post__meta__related-issues,
          &.post__meta__related-partners,
          &.post__meta__related-resources,
          &.post__meta__related-news,
          &.post__meta__related-posts {
            border-bottom: none;
            border-left: 2em solid $orange;
            padding-left: 1em;
            padding-bottom: 0;
            display: block;
          }
          &.post__meta__related-issues {
            border-color: $purple;
          }
          &.post__meta__related-partners {
            border-color: $blueMedium;
          }
          &.post__meta__related-news {
            border-color: $blueLight;
          }
          &.post__meta__related-posts {
            border-color: $yellow;
          }
          .related__values {
            @include typeDefaultLarge;
            @include typeAlt;
            line-height:1.2em;
          }
          .related__links {
            padding-top:.333em;
            a {
              @include typeDefault;
              @include typeLink;
              font-size:em(14,21);
              display: block;
              line-height: 1.2em;
              margin-bottom: 0.5em;
              &:last-child {
                margin-bottom: 0;
              }
            }
            @include media-breakpoint-down(sm) {
              padding-top:.1em;
            }
          }
          label {
            margin-right: 1em;
            @include typeDefaultLarge;
            @include typeAlt;
            line-height:1.2em;
            font-weight: 200;
            margin-bottom:.5em;
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      & {
        .post__section-main .row {
          flex-wrap: wrap;
        }
      }
      .post__content {
        padding-right: math.div($grid-gutter-width, 2);
        order: 2;
      }
      .post__meta {
        order: 1;
        margin: #{-1 * $grid-gutter-width} 0 $grid-gutter-width;
      }
    }
  }
}

.post__content,.block-editor-writing-flow,.post__subsection-results {
  @include typeInContent;
  .component {
    font-size:$typeBase;
  }
}
