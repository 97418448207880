.component-national-map {
  margin-top:4 * $grid-gutter-width;
  margin-bottom:4 * $grid-gutter-width;
  &.processing {
    .map__map {
      opacity:.5;
    }
  }
  .map__content {
    padding:em(60) 0;
    .map__headline {
      @include typeHeaderSuper;
    }
    .map__subheadline {
      font-weight:normal;
      text-transform:none;
      font-family: $fontBase;
    }
  }
  .map__legend,.location__legend {
    li {
      &:before {
        content:"";
        background:$yellow;
      }
      &:last-child {
        &:before {
          margin-bottom:0;
        }
      }
      &.issue-108 {
        &:before {
          background:$blueLight;
        }
      }
      &.issue-1384 {
        &:before {
          background:$orange;
        }
      }
      &.issue-6792 {
        &:before {
          background:$yellow;
        }
      }
      display:flex;
      align-items:center;
    }
  }
  .map__legend {
    @extend .component,.component-blue-darkest,.component-contrast;
    position:absolute;
    right:0;
    bottom:0;
    padding:$grid-gutter-width;
    li {
      &:before {
        height:2em;
        width:2em;
        margin-right:1em;
        flex:none;
      }
      margin-bottom:.5em;
      line-height:1.2em;
    }
    ul {
      margin:0;
    }
  }
  .location__legend {
    width:100%;
    flex:none;
    margin-bottom:1.5em;
    margin-top:1em;
    li,ul {
      list-style:none;
    }
    li {
      &:before {
        height:1em;
        width:1em;
        margin-right:.5em;
      }
      margin-right:2em;
      flex:none;
    }
    ul {
      display:flex;
      align-items:center;
      padding:0;
      margin:0;
      flex-wrap:wrap;
    }
  }
  .map__map {
    @extend .component, .component-blue-medium;
    padding-top:2 * $grid-gutter-width;
    padding-bottom:2 * $grid-gutter-width;
    position:relative;
    @include media-breakpoint-down(xl) {
      padding-bottom: em(244);
    }
  }
  .map__mobile {
    display:none;
    @extend .component,.component-blue-darkest;
    @include media-breakpoint-down(md) {
      display:block;
    }
    h3 {
      @include typeContrast;
    }
    form {
      display: inline-block;
      margin: 0 0 em(50);
      select {
        background:$light;
        @include typeAlt;
        optgroup {
          margin-top:0;
          padding-top:0;
          &:empty {
            display:none;
          }
        }
      }
    }
  }
  .map__related {
    @extend .component, .component-blue-darkest, .component-contrast;
    .map__location {
      //max-height:0;
      overflow:hidden;
      transition:max-height 1s;
      display:none;
      padding: em(30) 0 em(120);
      &.active {
       // max-height:1000px;
        display:block;
      }
      h3 {
        @include typeHeaderSuper;
        margin:.5em 1em .5em 0;
      }
      header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom:$grid-gutter-width;
        .btn,p {
          @include typeDefaultUp;
          @include typeContrast;
        }
        .btn {
          margin-top: 1.333em;
          margin-bottom: 1em;
        }
        p {
          flex:100%;
        }
      }
    }
    .map__location__inner {
      &:not(.has-impacts) {
        .partners {
          order:1;
        }
        .children {
          order:2;
        }
      }
      ul {
        margin-bottom:2em;
        li {
          line-height:1.2em;
          margin-bottom:.5em;
        }
        &.list-accordion {
          .accordion__item {
            &.open {
              .accordion__item__header {
                &:before {
                  content:"- ";
                }
              }
              .accordion__item__content {
                max-height:500px;
                overflow:hidden;
                padding-top:.5em;
              }
            }
            .accordion__item__header {
              @include typeDefaultLarge;
              @include typeContrast;
              display:flex;
              align-items:top;
              line-height:1.2em;
              &:before {
                content:"+ ";
                font-weight:bold;
                font-size:1.5em;
                margin-right:.5em;
                display:inline-block;
                width:.75em;
                text-align:center;
                flex:none;
              }
              a {
                text-decoration:none;
                &:hover {
                  text-decoration:underline;
                }
              }
            }
            .accordion__item__content {
              max-height:0;
              overflow:hidden;
              transition:all .5s ease-out;
              padding:0 0 0 2.8em;
              line-height:1.4em;
              p {
                &:first-child {
                  a {
                    color:$blueMedium;
                    font-weight:bold;
                  }
                }
              }
            }
          }
        }
      }
      .list-children {
        columns:2;
        @include typeDefaultLarge;
        @include typeContrast;
      }
      .partners,.impacts,.children {
        > h4 {
          @include typeDefaultXL;
          @include typeContrast;
          font-weight:bold;
          line-height:1.4em;
        }
      }
      .partners {
        h4 {
          &.past {
            color:$blueLight;
          }
          &.current {
            color:$yellow;
          }
        }
      }
      .impacts {
        margin-bottom:em(50);
      }
    }
  }
  svg {
    #states > g,#locals > g {
      &.active,&.hover,&.combined-active,&:hover {
        &.has-issues {
          .state {
            stroke:$blueDark;
            stroke-width:3px;
          }
        }
      }
      &.has-issues {
        cursor:pointer;
      }
      &.issue-1384,&.issue-6792,&.issue-108 {
        .state {
          fill-opacity:1;
        }
      }
      &.issue-1384 {
        //Eliminating Racial & Ethnic Disparities
        .state {
          fill:$orange;
        }
      }
      &.issue-6792 {
        //Expanding Abortion Access
        .state {
          fill:$yellow;
        }
      }
      &.issue-108 {
        //Increasing Access to Contraception
        .state {
          fill:$blueLight;
        }
      }
      &.issue-1384.issue-6792 {
        .state {
          fill:url("#pattern-yellow-orange");
        }
      }
      &.issue-108.issue-6792 {
        .state {
          fill:url("#pattern-yellow-blue");
        }
      }
      &.issue-108.issue-1384 {
        .state {
          fill:url("#pattern-blue-orange");
        }
      }
      &.issue-108.issue-6792.issue-1384  {
        .state {
          fill:url("#pattern-yellow-blue-orange");
        }
      }

      path.label,&.label path{
        fill:$blueDark;
      }
      .label {
        .spacer {
          fill:transparent;
          stroke:none;
        }
      }
      .state {
        fill:#fff;
        fill-opacity:.2;
        transition:all .2s;
        stroke-width:0;
      }
    }
    #locals {
      transform-origin:center center;
      transform:rotate(-.66deg) scale(1.1,1.1) translate(-2.9%,1%);
    }
    .local {
      display:none;
      &.active,&.hover,&.combined-active,&:hover {
        transform:scale(1.1);
        .location {
          fill:$light;
          stroke:$blueDarkest;
          stroke-width:2px;
        }
      }
      &.past,&.current {
        display:block;
      }
      .location {
        transform-box: fill-box;
        transform-origin:50% 50%;
        transform:translate(-50%,-50%);
        fill:$blueDarkest;
        stroke:$light;
        stroke-width:1px;
        cursor:pointer;
        transition:all .4s ease-out; 
      }
    }
  }
}

.component-tooltip-national-map {
  position:absolute; 
  padding-bottom:20px;
  transform:translate(-50%,calc(-100% + 10px));
  cursor:pointer;
  .tooltip__content {
    background:$light;
    padding:.25 * $grid-gutter-width $grid-gutter-width;
    border-radius:2px;
    border:3px solid $blueDarkest;
    line-height:1.2em;
    text-align:center;
    @include materialShadow;
    min-width:150px;
    &.has-siblings {
      strong {
        text-decoration:underline;
        &:hover {
          color:$blueMedium;
        }
      }
    }
  }
}