.component-meet-staff-cta {
  padding-top:em(125);
  padding-bottom:em(125);
  .cta {
    .btn {
      @include typeHeaderLarge;
      @include typeContrast;
      padding:1em;
      width:100%;
      max-width:466px;
      margin-left:auto;
      margin-right:auto;
      display:block;
      &:hover {
        color:$purple;
      }
    }
  }
}