.component-strategy {
  &.component-strategy-dark {
    .strategy__headline, .strategy__content {
      @include typeContrast;
    }
  }
  &.component-strategy-left {
    .strategy__background-half {
      left: 50%;
    }
    .highlight__content {
      padding-right: 5em;
      border-radius: 0 4px 4px 0;
    }
    .highlight__squiggle {
      right: -1em;
    }
    .strategy__collage {
      right: 0;
      left: auto;
    }
  }
  &.component-strategy-right {
    .component-highlight {
      justify-content: flex-end;
    }
    .highlight__content {
      padding-left: 4em;
      border-radius: 4px 0 0 4px;
      box-sizing: content-box;
      min-width: $container-max * math.div(5, 12) - $grid-outer-padding;
    }
    .highlight__squiggle {
      left: -2em;
      transform: translateY(-50%) scaleX(-1);
      display:none;
      @include media-breakpoint-down(lg) {
        right: -1em;
        left: auto;
        transform: translateY(-50%);
      }
    }
    .strategy__collage {
      left: 0;
      right: auto;
    }
    .strategy__background {
      background-position:left center;
      @include media-breakpoint-down(lg) {
        background-position: left 10% center;
      }
      @include media-breakpoint-down(md) {
        background-position: left 15% center;
      }
      &.strategy__background-half {
        background-position:center center;
      }
    }
  }
  &.component-strategy-has_highlight {
    .strategy__content {
      padding-top: 0;
    }
  }
  &.component-strategy-has_background_half {
    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
      .strategy__background,.strategy__collage {
        &,&.strategy__background-half,&.strategy__collage-half {
          order: 3;
          width: 100%;
          position: static;
          height: 50vh;
        }
        .component__highlight {
          order:1;
        }
        > .container {
          order: 2;
        }
      }
    }
  }
  &.component-strategy-headquarters {
    background:$grayBG;
  }
  &.component-strategy-map {
    background:$blueMedium;
  }
  .component-highlight {
    display: flex;
    overflow: visible;
    .highlight__content {
      margin-top: em(40, 23);
      margin-bottom: em(40, 23);
    }
    @include media-breakpoint-down(lg) {
      .highlight__content {
        margin-top: em(30);
        margin-bottom:em(38);
        width:auto;
        max-width: 100%;
        padding-left: $grid-outer-padding;
        padding-right: $grid-outer-padding;
        border-radius: 0;
        box-sizing: border-box;
      }
      .highlight__squiggle {
        right:auto;
        margin-left:em(40);
      }
    }
    @include media-breakpoint-down(sm) {
      .highlight__content {
        width: 100%;
        padding-left: $grid-outer-padding-mobile;
        padding-right: em(120);
      }
      .highlight__squiggle {
        right:em(10);
        margin-left:em(40);
      }
    }
  }
  .strategy__background {
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: right center;
    @include media-breakpoint-down(lg) {
      background-position: right 10% center;
    }
    @include media-breakpoint-down(md) {
      background-position: right 15% center;
    }
    &.strategy__background-half {
      width: 50%;
      background-size: cover;
      background-position: center center;
    }
  }
  .strategy__content {
    padding-top: em(80);
    padding-bottom: em(80);
    @include typeDefaultUp;
    @include typeAlt;
  }

  .strategy__headline {
    @include typeHeaderMid;
  }

  .strategy__action {
    margin-top: 2em;
  }
  .strategy__collage {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &.strategy__collage-half {
      width: 50%;
    }
    .collage__item {
      //border: 1px solid $light;
      background: transparent center center no-repeat;
      background-size: cover;
      grid-row: auto / span 1;
      grid-column: auto / span 1;
      &:first-child,
      &:last-child {
        grid-column: auto / span 2;
      }
    }
  }
}
