.home {
  .component-hero-with-cta {
    height: 100vh;
    text-align: center;
    .hero__content {
      position: absolute;
      bottom:0;
      left: 50%;
      z-index: 2;
      margin: 0 auto;
      transform: translateX(-50%);
      .hero__content__inner {
        margin: 0 auto;
      }
    }
    .hero__headline {
      @include typeHeaderLarge;
      line-height:1.45em;
    }
  }
}
