.component-join-nirh {
  padding-top:em(100);
  padding-bottom:em(125);
  h2 {
    @include typeHeaderSuper;
    color:$blueDark;
    margin-bottom:1em;
  }
  .component__content {
    align-items:center;

  }
  .component__description {
    margin-bottom:0;
    p {
      &:last-child {
        margin-bottom:0;
      }
    }
  }
  .component__actions__group {
    display:flex;
    flex-direction:column;
    justify-content:center;
    .btn {
      flex:1;
      @include typeHeaderMid;
      padding:em(48,35);
      width:100%;
      margin-bottom:1em;
      color:$blueDark;
      border-color:$blueDark;
      &:hover {
        background:$blueDark;
        color:$light;
      }
      &:last-child {
        @include typeHeaderSmall;
        margin-bottom:0;
        padding:em(48,24);
      }
    }
  }
  @include media-breakpoint-down(md) {
    .component__description {
      margin-bottom:2em;
    }
    .component__actions__group {
      .btn {
        @include typeHeaderSmall;
        padding:em(48,24);
      }
    }
  }
}