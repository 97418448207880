.nav-utility {
  .nav-item {
    @include typeHeaderNavReduced;
    .nav-link {
      padding: 0;
      margin-right: 2em;
    }
  }
}

.navbar-collapse.show,
.navbar-collapse.collapsing {
  .nav-utility {
    .nav-item {
      .nav-link {
        margin-right: 0;
        margin-bottom: 0.5em;
      }
    }
  }
}
