.search-results {
  .post__section-filters {
    .component-archive-filters {
      &,&.reduced {
        form#searchform {
          .search__field__wrapper {
            border-bottom:none;
          }
        }
      }
    }
  }
  .post__section-summary {
    h2,.summary__info {
      @include typeDefaultLarger;
      @include typeAlt;
      margin:0;
    }
    h2 {
      em {
        font-style:normal;
        font-weight:bold;
      }
    }
    .row {
      padding:em(60) 0 em(12);
    }
  }
  .post__section-archive {
    .post__preview {
      &.post__preview-plain {
        background:$grayBG;
        padding:em(30) em(23) em(39);
        margin-bottom:$grid-gutter-width;
        overflow:auto;
        > a {
          display:block;
          height:100%;
          width:100%;
        }
        .post__image {
          height:em(90);
          width:em(90);
          background-position:center center;
          background-size:cover;
          float:left;
          margin:0 em(18) 0 0;
          padding:0;
        }
        .post__title {
          @include typeDefault;
        }
        .post__type {
          color:$purple;
          font-style:normal;
        }
      }
    }
  }
  .post__subsection-results {
    .component-pagination {
      @include typeDefaultLarger;
      @include typeAlt;
    }
  }
}