%link-solid {
  padding: em(16, 22);
  border: none;
  color: inherit;
  transition: all 0.25s ease-out;
  &,&:hover {
    text-decoration:none !important;
  }
}

%link-yellow,%link-bright {
  background: $yellow;
  color: $blueDark;
  &:hover {
    background: darken($yellow, 10%);
  }
}

%link-orange,%link-bright-alt {
  background: $orange;
  color: $blueDark;
  &:hover {
    color: $light;
    background: darken($orange, 10%);
  }
}

%link-purple,%link-dark {
  background: $purple;
  color: $light;
  &:hover {
    background: darken($purple, 10%);
  }
}

%link-blue {
  background: $blueMedium;
  color: $light;
  &:hover {
    background: darken($blueMedium, 10%);
  }
}

%link-blue-dark {
  background: darken($blueMedium, 10%);
  color: $light;
  &:hover {
    background: darken($blueMedium, 14%);
  }
}


.link-solid {
  @extend %link-solid;
  @extend %link-blue;
  &.link-bright,&.link-yellow {
    @extend %link-yellow;
  }
  &.link-bright-alt,&.link-orange {
    @extend %link-yellow;
  }
  &.link-dark,&.link-purple {
    @extend %link-purple;
  }
}
