.single-staff {
  .post__meta {
    text-align:center;
    .post__image {
      width: 100%;
      padding-top: 100%;
      background-position: center center;
      background-size: cover;
      border-radius: 100%;
      @include materialShadow;
      max-width: 350px;
      max-height: 350px;
      margin-bottom:em(40);
    }
    @include media-breakpoint-down(lg) {
      text-align:left;
      .post__image {
        padding-top:350px;
      }
    }
  }
}