.post__related {
  margin-bottom: em(60);
  > .row {
    flex-wrap:wrap;
  }
  .post__preview {
    &.card-default {
      @include media-breakpoint-down(xl) {
        padding-top:125%;
      }
      @include media-breakpoint-down(lg) {
        padding-top:55%;
      }
      @include media-breakpoint-down(sm) {
        padding-top:95%;
      }
    }
  }
  .post__related__additional {
    @extend .component, .component-contrast, .component-blue-dark;
    padding:$grid-gutter-width 2 * $grid-gutter-width;
    a {
      @include typeDefaultLarge;
      @include typeLinkContrast;
    }
    ul,li {
      list-style:none;
    }
    li {
      margin-bottom:1.2em;
    }
    @include media-breakpoint-down(md) {
      padding-left:$grid-gutter-width;
      padding-right:$grid-gutter-width;
    }
  }
}