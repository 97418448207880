.post__preview-news {
  &.card-simple {
    background:none;
    border:none;
    .card__wrapper {
      padding:em(32) 0 em(26);
    }
    .card__content {
      .post__title {
        @include typeDefault;
        @include typeAlt;
        margin:0;
        &:hover {
          text-decoration:underline;
        }
      }
    }
    .card__meta {
      @include typeDefault;
      color:$purple;
      .post__outlet {
        color:$blueMedium;
        font-weight:600;
        font-style:italic;
      }
      span {
        display:inline-block;
        &:first-child {
          &:after {
            content:" | ";
            display:inline-block;
            margin:0 .8em 0 1em;
          }
        }
        &:last-child {
          &:after {
            content:none;
          }
        }
      }
    }
  }
}