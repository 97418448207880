.component-donate {
  @include typeAlt;
  .donate__content {
    padding-top: em(85);
    padding-bottom: em(118);
  }
  .donate__content__inner {
    margin-bottom: em(32.5);
  }
  .donate__headline {
    @include typeHeaderMid;
    font-size: em(42);
    @include media-breakpoint-down(sm) {
      font-size: em(32);
    }
  }
  .donate__actions {
    text-align: right;
  }
  .donate__actions__group {
    display: grid;
    grid-template-columns: calc(50% - #{em(16.25)}) calc(50% - #{em(16.25)});
    grid-gap: em(32.5);
    margin-bottom: em(32.5);
    .donate__action {
      grid-rows: auto / span 1;
      grid-columns: auto / span 1;
    }
  }
  .donate__action {
    display: block;
    @include typeHeaderLarge;
    text-align: center;
    padding: 0.9em 0;
  }

  .donate__action-default {
    @include typeDefaultLarge;
  }
}
