@import url("//use.typekit.net/ndb5rrn.css");

$typeBase: 16px;
$fontBase: "proxima-nova", "Arial", sans-serif;
$fontHeader: "dunbar-low", "Arial", sans-serif;

@function em($px, $base: 16) {
  @return (math.div($px, $base)) * 1em;
}

@mixin typeDefault {
  color: $dark;
  font-family: $fontBase;
  font-size: $typeBase;
  font-weight: normal;
  line-height: 1.9em;
  text-transform: none;
}

@mixin typeDefaultLineReduced {
  line-height: 1.4em;
}

@mixin typeDefaultReduced {
  @include typeDefault;
  font-size: em(12);
}

@mixin typeDefaultUp {
  @include typeDefault;
  font-size: em(16);
}

@mixin typeDefaultMid {
  @include typeDefault;
  font-size: em(18);
  @include media-breakpoint-down(sm) {
    font-size: em(16);
  }
}

@mixin typeDefaultLarge {
  @include typeDefault;
  font-size: em(21);
  @include media-breakpoint-down(sm) {
    font-size: em(16);
  }
}

@mixin typeDefaultLarger {
  @include typeDefault;
  font-size: em(23);
  @include media-breakpoint-down(lg) {
    font-size: em(21);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(16);
  }
}

@mixin typeDefaultXL {
  @include typeDefault;
  font-size: em(30);
  @include media-breakpoint-down(lg) {
    font-size: em(27);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(20);
  }
}

@mixin typeAlt {
  color: $blueDark;
}

@mixin typeContrast {
  color: $light;
}

@mixin typeInContent {
  @include typeDefault;
  @include typeAlt;
  font-size:16px;
}

@mixin typeLink {
  color: $magenta;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  &:hover {
    color: darken($magenta,10%);
  }
}

@mixin typeLinkContrast {
  color: $light;
  &:hover {
    color: darken($light,10%);
  }
}

@mixin typeLinkAlt {
  color: $blueDark;
  &:hover {
    color: $blueDark;
  }
}

@mixin typeNavLink {
  color: $blueDark;
  line-height: 1.2em;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

@mixin typeNavLinkEm {
  @include typeNavLink;
  font-size: em(18);
}

@mixin typeNavLinkLarge {
  @include typeNavLink;
  font-size: em(24);
}

@mixin typeQuoteTestimonial {
  font-family: $fontHeader;
  font-size:em(33);
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  line-height:em(53,33);

  figcaption {
    color:$dark;
    font-size:em(18,33);
    letter-spacing:.5px;
    line-height:em(43,18);
  }

  @include media-breakpoint-down(md) {
    font-size:em(24);
  }
}

@mixin typeHeader {
  font-family: $fontHeader;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}

@mixin typeHeaderXS {
  @include typeHeader;
  font-size: em(16);
  line-height: 1em;
}

@mixin typeHeaderSmall {
  @include typeHeader;
  font-size: em(24);
  line-height: 1em;
  @include media-breakpoint-down(sm) {
    font-size: em(18);
  }
}

@mixin typeHeaderMid {
  @include typeHeader;
  font-size: em(35);
  line-height: 1.228em;
  margin-bottom: 1.666em;
  @include media-breakpoint-down(sm) {
    font-size: em(29);
  }
}

@mixin typeHeaderNav {
  @include typeHeader;
  font-size: em(22);
  @include media-breakpoint-down(xl) {
    font-size: em(20);
  }
}

@mixin typeHeaderBright {
  @include typeHeaderNav;
  color: $blueMedium;
}

@mixin typeHeaderNavReduced {
  @include typeHeaderNav;
  font-size: em(16);
}

@mixin typeHeaderInContent {
  @include typeHeader;
  @include typeAlt;
  font-size: em(44,16);
  line-height: 1.2em;
  @include media-breakpoint-down(sm) {
    font-size: em(36,16);
  }
}

@mixin typeHeaderLarge {
  @include typeHeader;
  font-size: em(52);
  line-height: 1em;
  @include media-breakpoint-down(md) {
    font-size: em(46);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(32);
  }
}

@mixin typeHeaderSuper {
  @include typeHeader;
  font-size: em(60);
  line-height: 1.1em;
  @include media-breakpoint-down(md) {
    font-size: em(47);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(32);
  }
}

@mixin typeHeaderSuperLarge {
  @include typeHeader;
  font-size: em(86);
  line-height: 1.1em;
  @include media-breakpoint-down(md) {
    font-size: em(72);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(40);
  }
}

@mixin typeHeaderSuperXL {
  @include typeHeader;
  font-size: em(108);
  line-height: 1.1em;
  @include media-breakpoint-down(md) {
    font-size: em(90);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(50);
  }
}

@mixin typeHeaderCardCover {
  @include typeDefault;
  color: $blueDark;
  line-height: 1.2em;
  font-size: em(26);
  font-weight: 200;
  @include media-breakpoint-down(lg) {
    font-size: em(23);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(20);
  }
}

@mixin typeHeaderCardDefault {
  @include typeDefault;
  color: $blueDark;
  line-height: 1.2em;
  font-size: em(18);
  font-weight: 600;
  @include media-breakpoint-down(xl) {
    font-size: em(15);
    letter-spacing: 0;
  }
}

@mixin typeHeaderCardMid {
  @include typeHeader;
  color: $blueDark;
  line-height: em(30,20);
  font-size: em(20);
  font-weight: 600;
  @include media-breakpoint-down(lg) {
    font-size: em(18);
  }
  @include media-breakpoint-down(sm) {
    font-size: em(16);
  }
}

@mixin typeHeaderTimeline {
  @include typeDefault;
  color: $purple;
  font-size:em(30);
  margin:0 0 .33em;
}

@mixin typeInput {
  @include typeHeader;
  font-size: em(16);
}

@mixin typeInputContrast {
  @include typeContrast;
}

@mixin typeInputAlt {
  &::placeholder,
  option:first-child {
    color: $grayMedium;
  }
  @include typeDefault;
  color: $blueDark;
}

@mixin typeButton {
  @include typeDefault;
  color: $blueDark;
  font-size: em(16);
  line-height: 1.2em;
}

@mixin typeButtonLarge {
  @include typeButton;
  font-size: em(22);
}

@mixin typeButtonEm {
  @include typeButton;
  font-family: $fontHeader;
  text-transform:uppercase;
  font-weight:bold;
  font-size: em(19);
}
