.nav__menu {
  &,
  .nav__menu-item {
    list-style: none;
    padding: 0;
    margin: 0;
    &:hover {
      .dropdown-menu {
        max-height: 800px;
        padding-top: em(42);
        padding-bottom: em(42);
        z-index: 3;
      }
    }
    &.dropdown-super {
      position: static;
    }
    .dropdown-menu {
      display: inline-block;
      max-height: 0;
      overflow: hidden;
      padding: 0 #{2 * $grid-gutter-width};
      transition: all 0.3s ease-out 0.3s;
      border-radius: 0;
      border: none;
      box-sizing: border-box;
      left: 0;
      z-index: 2;
      box-shadow: $card-shadow;
      @include typeDefault;
      &.dropdown-menu-super {
        width: calc(100% + #{4 * $grid-gutter-width});
        left: #{-2 * $grid-gutter-width};
        .dropdown-item,
        .nav__menu-item {
          width: auto;
        }
      }
      h2 {
        @include typeHeaderBright;
      }
      .dropdown-item,
      .nav__menu-item {
        @include typeNavLinkEm;
        padding: 0;
        margin-bottom: 1em;
        width: 300px;
        white-space: normal;
        &:hover {
          background: none;
        }
        .nav-link {
          padding: 0;
          background: none;
        }
      }
    }
  }
  .nav-item {
    &.mobile-only {
      display:none;
      @include media-breakpoint-down(lg) {
        display:block;
      }
    }
  }
  .nav-link {
    @include typeNavLink;
    padding: 0;
  }
}

.nav-super__group {
  &:first-child {
    h2 {
      margin-top:0;
    }
  }
  h2 {
    @include typeHeaderNav;
    margin-bottom: 1em;
    margin-top:1.5em;
  }
  .nav-link {
    margin-bottom: 1em;
  }
}

.navbar-brand {
  font-size: 1em;
}

.navbar-collapse {
  &.show,
  &.collapsing {
    .nav__menu {
      .nav__menu-item {
        &:hover {
          .dropdown-menu {
            max-height: 0;
            padding: 0;
          }
        }
      }
    }
  }
}
