#site-footer {
  padding: em(130) 0 em(30);
  .branding-footer {
    display: block;
    padding: 0;
    img {
      height: em(90);
    }
  }
  .footer-content {
    display: grid;
    grid-template-columns: 55% 45%;
  }
  .footer-pre,
  .footer-secondary {
    grid-column: 2;
    padding-left: 20%;
    > * {
      margin-bottom: em(32);
    }
  }
  .footer-pre {
    .nav-social {
      margin-top: em(30);
    }
  }
  .footer-main {
    display: flex;
    grid-column: 1;
    grid-row: 1 / span 2;
    align-items: flex-start;
    .footer-main__primary,
    .footer-main__secondary {
      margin-bottom: 3.4em;
      height:calc(100% - 3.4em);
    }
    .footer-main__primary {
      width: 33%;
      flex: 33%;
      border-right: 1px solid $light;
      padding-right: 2 * $grid-gutter-width;
    }
    .footer-main__secondary {
      width: 66.666%;
      flex: 66.666%;
      padding-left: 2 * $grid-gutter-width;
    }
  }
  .footer-secondary {
    grid-row: 2;
  }
  .footer-post {
    grid-row: 3;
    grid-column: 1 / span 3;
    margin-top: em(72);
  }
  .nav-super__group {
    margin-bottom: 3em;
    h2 {
      margin-bottom: 0.7em;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  form#searchform {
    label {
      display:none;
    }
    .search__field {
      &::placeholder {
        color:$light;
      }
    }
    .search__actions {
      top: -1px;
      &,button {
        height: calc(100% + 1px); 
      }
      button {
        background:none;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .branding-footer {
      img {
        margin-top: -6px;
        height: em(116);
      }
    }
    .footer-content {
      grid-template-columns: 100%;
    }
    .footer-pre,
    .footer-secondary,
    .footer-main,
    .footer-post {
      grid-column: 1;
    }
    .footer-pre,
    .footer-secondary {
      padding-left: 0;
    }
    .branding-footer {
      width: 33.333%;
      float: left;
      margin-right: 2 * $grid-gutter-width;
    }
    .footer-pre {
      grid-row: 1;
      margin-bottom: 3.4em;
      display:flex;
      .footer-pre-primary {
        width:calc(33% + 60px);
      }
    }
    .footer-main {
      grid-row: 2;
      margin-bottom: em(36);
    }
    .footer-secondary {
      grid-row: 3;
    }
    .footer-post {
      grid-row: 4;
    }
  }
  @include media-breakpoint-down(sm) {
    text-align: center;
    justify-content: center;
    .branding-footer {
      margin-left: auto;
      margin-right: auto;
      float: none;
      width:auto;
      img {
        margin-top: 0;
        height: em(90);
      }
    }
    .footer-pre {
      border-bottom: 1px solid $light;
      display:block;
      .footer-pre-primary {
        width:100%;
      }
      .nav-social {
        justify-content: center;
      }
    }
    .footer-main {
      display: block;
      .footer-main__primary,
      .footer-main__secondary {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        height:auto;
      }
      .footer-main__primary {
        border-right: none;
        border-bottom: 1px solid $light;
        padding-bottom: 2.4em;
      }
    }
    .nav-super__group {
      h2 {
        margin-bottom:1.8em;
      }
    }
  }
}

.footer-logos-charity {
  img {
    height: 4.5em;
    width: auto;
  }
}
