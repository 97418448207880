.component-pills {
  &.pills-plain {
    line-height:1.2em;
    .pill,
    .rounded-pill,
    .badge {
      @include typeDefaultReduced;
      @include typeDefaultLineReduced;
      background: none;
      color: $blueDark !important;
      margin-right: 0.25em;
      margin-top:1em;
      padding: 0;
      width: auto;
      text-align: left;
      display: inline;
      vertical-align:baseline;
      &:after {
        content: " / ";
        display: inline-block;
        margin-left: 0.5em;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
      &:hover {
        filter:none;
      }
    }
    a {
      cursor:pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .pill,
  .rounded-pill,
  .badge {
    font-weight: normal;
    background: $blueDark;
    padding: 3px 12px;
    transition: filter 0.33s ease-out;
    vertical-align:top;
    white-space:normal;
    margin: 0 0.5em 0.8em 0;
    &.pill-issue,
    &.pill-partner {
      color: $light !important;
    }
    &.pill-issue {
      background: $purple;
    }
    &.pill-location {
      background: $blueLight;
      color: $blueDark;
    }
    &.pill-partner,
    &.pill-blue,
    &.pill-now,
    &.pill-win {
      background: $blueMedium;
    }
    &.pill-critical,
    &.pill-orange {
      background: $orange;
    }
    &.pill-yellow,
    &.pill-historic {
      background: $yellow;
    }
    &:hover {
      filter: brightness(0.9);
    }
  }
}
