.component-headshot-quote {
  padding-top:$grid-gutter-width;
  padding-bottom:2 * $grid-gutter-width;
  h2.quote__title {
    .single .post__content &,.block-editor-writing-flow & {
      @include typeHeaderXS;
      @include typeContrast;
      margin:0;
    }
  }
  .quote__quote {
    margin:$grid-gutter-width auto 0;
    background:$light;
    position:relative;
    padding:$grid-gutter-width;
    @include typeAlt;
    @include materialShadow;
    blockquote {
      margin:0;
      border:5px solid $blueMedium;
      padding:1.5 * $grid-gutter-width 2*$grid-gutter-width 2 * $grid-gutter-width 33.333%;
      line-height:1.8em;
      span {
        position:relative;
        &:before {
          position: absolute;
          content: "";
          right: 120%;
          top: -1.8em;
          background: transparent url(images/component-quote-open-dark.png) 50% no-repeat;
          height: 2.6em;
          width: 2.6em;
          background-size: contain;
        }
      }
    }
    figcaption {
      margin-top:1.8em;
      text-transform:uppercase;
      font-size:.9em;
      line-height:1.2em;
      &:before {
        content: "\2013 ";
        display:inline-block;
        margin-right:.5em;
      }
    }
  }
  .quote__image {
    position: absolute;
    top: -.5 * $grid-gutter-width;
    left: -2 * $grid-gutter-width;
    width: 33.333%;
    height: auto;
    padding-top: 33.333%;
    margin: 0;
    overflow:hidden;
    border-radius:100%;
    img {
      position:absolute;
      top:50%;
      left:50%;
      height: 100%;
      width: auto;
      max-width:none;
      transform:translate(-50%,-50%);
    }
  }
}