.component-email {
  .email__content {
    padding-top: em(80);
    padding-bottom: em(80);
    align-items: center;
    @include media-breakpoint-down(lg) {
      text-align: center;
      justify-content: center;
    }
    form {
      display: flex;
      input[type="email"] {
        border-width: 0 0 2px;
        margin-right: 1em;
        display: inline-block;
        flex: auto;
        @include typeDefault;
        @include typeContrast;
        &::placeholder {
          color: $light;
        }
      }
      @include media-breakpoint-down(lg) {
        margin-top: 2em;
      }
    }
  }
}
