.component {
  position: relative;
  &.component-contrast {
    @include typeContrast;
    a {
      @include typeLinkContrast;
    }
  }
  &.component-neutral {
    color: $dark;
    a {
      color: $blueDark;
    }
  }
  &.component-blue-lighter {
    background: $blueLighter;
  }
  &.component-blue-light {
    background: $blueLight;
  }
  &.component-blue-medium {
    background: $blueMedium;
  }
  &.component-blue-gray {
    background: rgba($blueGray,.1);
  }
  &.component-blue-dark {
    background: $blueDark;
  }
  &.component-blue-darkest {
    background: $blueDarkest;
  }
  &.component-gradient-blue-light {
    background: transparent
      linear-gradient(175deg, $blueLight 0%, $blueGrayMedium 100%) 0% 0%
      no-repeat;
  }
  &.component-gradient-blue-medium {
    background: transparent
      linear-gradient(
        180deg,
        #04889E 0%,
        #87D5CE 100%
      )
      0% 0% no-repeat;
  }
  &.component-gradient-yellow {
    background: transparent linear-gradient(180deg, $yellow 0%, $pink 100%) 0%
      0% no-repeat;
  }
  &.component-gradient-blue-yellow {
    background: transparent
      linear-gradient(to bottom, $blueLight 0%, $yellow 100%) 0% 0%
      no-repeat;
  }
  &.component-purple {
    @extend .component-contrast;
    background: $purple;
  }
  &.component-gradient-purple-blue {
    @extend .component-contrast;
    background: transparent
      linear-gradient(
        175deg,
        $purple 0,
        $blueMediumAlt 100%
      )
      0% 0% no-repeat;
  }
  &.component-gradient-blue-purple {
    @extend .component-contrast;
    background: transparent
      linear-gradient(
        175deg,
        $blueMedium 0,
        $purple 100%
      )
      0% 0% no-repeat;
  }
}
