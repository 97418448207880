.component-testimonial-carousel {
  padding-top:em(86);
  padding-bottom:em(40);
  &.init {
    .testimonial__quote {
      &.active {
        display:flex;
        opacity:1;
      }
      opacity:0;
    }
  }
  .testimonial__primary {
    position:relative;
  }
  .testimonial__quote {
    display:flex;
    align-items:center;
    opacity:0;
    position:absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
    transition:opacity 1s ease-in-out;
    width:100%;
    blockquote {
      @include typeQuoteTestimonial;
      margin:0;
      padding:0;
      position:relative;
      &:before {
        position: absolute;
        content: "";
        right: calc(100% + .5em);
        top: -.333em;
        background: transparent url(images/component-quote-open.png) 50% no-repeat;
        height: 1em;
        width: 1.4em;
        background-size: contain;
      }
      span {
        &:after {
          display:inline-block;
          content: "";
          background: transparent url(images/component-quote-close.png) 50% no-repeat;
          height: 1em;
          width: 1.4em;
          margin-left:.5em;
          background-size: contain;
        }
      }
      figcaption {
        @include typeContrast;
        margin-top:em(14,18);
      }
      @include media-breakpoint-down(lg) {
        &:before {
          left:0;
          top: -1.333em;
        }
      }
    }
  }
  .testimonial__nav {
    ol,li {
      list-style:none;
      padding:0;
      margin:0;
    }
    ol {
      display:flex;
      justify-content:flex-end;
      margin-top:em(30);
    }
    li {
      &.active,&:hover {
        background:$light;
      }
      cursor:pointer;
      transition:background .25s ease-in-out;
      border:2px solid $light;
      height:em(20);
      width:em(20);
      border-radius:em(20);
      margin-left:em(7);
    }
  }
}