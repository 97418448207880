.post-type-archive-event {
  .post__content {
    .subsection__title {
      @include typeDefaultXL;
      @include typeAlt;
      margin-bottom:1em;
    }
  }
  .post__section-archive {
    padding-bottom:0;
  }
  .post__subsection-results {
    .component-strategy {
      &:nth-child(even) {
        background:$grayBG;
      }
      margin-bottom:0;
      .strategy__headline {
        margin-bottom:.33em;
      }
      .strategy__content {
        strong {
          display:block;
          color:$blueMedium;
        }
      }
    }
  }
  .post__subsection-upcoming {
    margin-bottom:0;
    .post__subsection__content {
      margin-top:em(55);
    }
  }
  .post__subsection-past {
    .post__subsection__content {
      background:$blueGrayLight;
      padding:em(60) 0 em(80);
    }
    .list-events {
      [class*=col-] {
        &:nth-child(odd) {
          border-right:1px solid $blueDark;
          padding-right:8%;
        }
        &:nth-child(even) {
          padding-left:8%;
        }
        @include media-breakpoint-down(lg) {
          &:nth-child(odd) {
            border:none;
            padding-right:#{math.div($grid-gutter-width,2)};
          }
          &:nth-child(even) {
            padding-left:#{math.div($grid-gutter-width,2)};
          }
        }
      }
    }
  }
}