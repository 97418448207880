body:not(.wp-admin) {
  form {
    &.form-alt {
      input[type="text"],
      input[type="password"],
      input[type="email"],
      input[type="search"],
      select {
        @include typeInputAlt;
        border: none;
        background: $light;
      }
    }
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="search"],
    select {
      border: 1px solid $dark;
      border-radius: 2px;
      background: none;
      background-position: center right 0.5em;
      background-repeat: no-repeat;
      @include typeInput;
      padding: 0.666em 1.333em;
      height:auto;
    }
    .form__field-select {
      position:relative;
      &:after {
        content:"";
        background:transparent url("images/icon-dropdown.png") center center no-repeat;
        background-size:contain;
        position:absolute;
        right: 0;
        top: 50%;
        height: 1.2em;
        width: 1.5em;
        transform: translate(-50%, -50%);
      }
      select {
        appearance:none;
        padding-right:3em;
      }
    }
  }
  .component-contrast form {
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="search"],
    select {
      @include typeInputContrast;
      border-color: $light;
    }
  }
}
