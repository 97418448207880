%page-archive {
  .no-results {
    margin-top:em(68,32);
  }
  .component-hero-with-cta {
    .hero__headline {
      @include typeHeaderSuperLarge;
    }
  }
  .post__section-archive {
    padding: em(12) 0 em(120);
    .component-archive-filters {
      margin-bottom: em(120);
      margin-top: em(-12);
    }
  }
  .addtl_results__control {
    .btn {
      display: block;
      margin: em(30) auto em(60);
    }
  }
  .addtl_results {
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.33s ease-in-out;
  }
  .post__subsection-results {
    .component-strategy {
      @extend .container-breakout-wide;
      margin-bottom: 30px;
      padding: 0;
      @media screen and (max-width: $container-wide-with-padding) {
        @include breakout-fix;
      }
    }
    .row {
      [class*=col-lg-6] {
        &.odd {
          padding-right:6px;
        }
        &.even {
          padding-left:6px;
        }
        @include media-breakpoint-down(lg) {
          &.odd,&.even {
            padding-left:#{math.div($grid-gutter-width,2)};
            padding-right:#{math.div($grid-gutter-width,2)};
          }
        }
      }
    }
    .post__preview.card-cover.compact {
      height:calc(100% - #{$grid-gutter-width});
    }
  }
}

.page-archive,
.post-type-archive,
.page-blog,
.search-results {
  @extend %page-archive;
}
