.component-pagination {
  @include typeDefaultLarger;
  @include typeAlt;
  margin:0;
  .pagination {
    margin:0;
    &:before {
      content: "Pages: ";
    }
    justify-content:flex-end;
    @include media-breakpoint-down(lg) {
      justify-content:flex-start;
    }
  }
  .page-item {
    
  }
  .page-link {
    @include typeNavLink;
    border:none !important;
    border-radius:0 !important;
    padding-top:0;
    padding-bottom:0;
    padding-left:4px;
    padding-right:4px;
    line-height: 1.9em;
    outline:none;
    &:hover,&:active,&:focus {
      background:none;
      outline:none;
      box-shadow:none;
    }
  }
}