.card {
  background: $light;
  box-shadow: $card-shadow;
  border-radius: 1px;
  border: none;
  margin-bottom: 1em;
  padding: 0;
  &.card-interactive {
    @extend %card-interactive;
  }
  .card__wrapper {
    padding: em(20) em(24);
    height:100%;
    flex:auto;
  }
}

%card-interactive {
  transition: all 0.33s ease-out;
  &:hover {
    box-shadow: $card-shadow-active;
    transform: translate(-2px, -2px);
  }
}
