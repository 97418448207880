.component-twitter-feed {
  overflow:hidden;
  .feed__feed {
    margin:em(72) 0 em(157);
  }
  #ctf {
    .ctf-item {
      @extend .card,.post__preview,.card-cluster;
      padding:$padding-cluster-y $padding-cluster-x;
      display:flex;
      flex-direction:column;
    }
    .ctf-author-name {
      &:before {
        content:"@";
      }
    }
    .ctf-author-box {
      padding-left:0 !important;
      order:2;
    }
    .ctf-author-avatar {
      display:none;
    }
    a[href*='/hashtag/'] {
      color:$blueMedium !important;
    }
    .ctf-tweet-content {
      display:flex;
      flex-direction:column;
      margin:0;
    }
    .ctf-tweet-text {
      order:2;
    }
    .ctf-tweet-meta {
      &,a {
        color:$purple !important;
        text-transform:uppercase;
      }
    }
    .ctf-tweet-media {
      order:1;
      margin-top:0;
      margin-bottom:.75em;
      a {
        &:after {
          border:none;
        }
      }
      .ctf-image {
        border-radius:0;
      }
    }
    .ctf-twitter-card {
      @include materialShadow;
      order:3;
    }
  }
}